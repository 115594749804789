.ui-notification {
	z-index: 1021;
	position: fixed;
	opacity: 0;
    top: 0;
    left: 0;
    right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background: white;
	background: var(--gris-texto);
    color: white;
	text-transform: uppercase;
	font-weight: bold;
}