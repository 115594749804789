.popup-cookies {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.popup-cookies.accepted {
    display: none !important;
}

.popup-cookies .btn {
    font-size: 13px;
    height: 36px;
    padding: 10px 30px;
    margin-top: 2em;
    margin-left: auto;
}

.popup-cookies {
    position: fixed;
    bottom: 30px;
    right: 30px;
    max-width: 450px;
    background: white;
    padding: 30px;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
    animation-name: popup-appear;
    animation-delay: 1s;
    animation-duration: 500ms;
    animation-fill-mode: forwards;
    transform: translateX(500px);
    border: 1px solid grey;
    z-index: 3;
}

@keyframes popup-appear {
    0% {
        transform: translateX(500px);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes popup-appear-bottom {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
    }
}

@media (max-width: 500px) {
    .popup-cookies {
        right: 0;
        bottom: 0;
        left: 0;
        max-width: unset;
        transform: translateY(100%);
        animation-name: popup-appear-bottom;
    }
}
