.header-preguntas-frecuentes {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 32vh 0 0;
    margin-bottom: 50px;
}

.preguntas-frecuentes h1 {
       max-width: 1280px;
    margin: 0 auto;
    color: white;
    background: rgba(0, 0, 0, 1);
    padding: 20px 30px 22px;
    display: inline-block;
    margin-left: 120px;
    font-size: 40px;
}

.preguntas-frecuentes .desplegable {
    opacity: 0;
    transition: 500ms;
}

.preguntas-frecuentes .desplegable .titulo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    border-radius: 5px;
    color: #404142;
    cursor: pointer;
    background-color: rgb(248 248 248);
    padding: 0.75em 1em;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

.preguntas-frecuentes .desplegable .titulo img {
    width: 17px;
    height: 10px;
    transition: 300ms;
}

.preguntas-frecuentes .desplegable .contenido {
    padding: 1.25em 1.25em 3em 1.25em;
    transition: 500ms;
}

.preguntas-frecuentes .desplegable .contenido.collapsed {
    margin-bottom: 2em;
}

@media (max-width: 1330px) {
    .header-preguntas-frecuentes {
        padding: 30vh 0 0;
    }

    .preguntas-frecuentes h1 {
        line-height: 1;
        margin-right: 0;
        margin-left: 0;
        width: 100%;
    }
}

@media (max-width: 991px) {
    .preguntas-frecuentes h1 {
        font-size: 33px;
        text-align: center;
    }
}
