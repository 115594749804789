@media(max-width: 992px) {
    body.scrolled .admin-page.admin-reservas .header,
    body.scrolled .admin .admin-page.admin-reservas .edit-page .header {
        height: 106px;
    }

    .admin-page.admin-reservas .orders-list .order-row {
        flex-direction: column;
    }

    .admin-page.admin-reservas .orders-list .order-row > div.drag-handle {
        display: none;
    }

    .admin-page.admin-reservas .orders-list .order-row > div {
        font-size: 11px;
        padding: 5px 10px;
        text-align: center;
        border: none;
    }

    .admin-page.admin-reservas .combo-select .selected-option {
        padding: 11px;
    }

    .admin-page.admin-reservas .new-order-row-details .order-details-row {
        flex-direction: column;
    }

    .admin-page.admin-reservas .new-order-row-details .order-details-row:first-of-type > div:nth-last-child(2) {
        width: 100%;
        margin-top: 1em;
    }

    .admin-page.admin-reservas .new-order-row-details .order-details-row:first-of-type > div:last-child {
        text-align: center;
        width: 100%;
    }

    .admin-page.admin-reservas .new-order-row-details .order-details-row .custom-field.type-currency {
        margin: 1em auto 0;
    }

    .admin-page.admin-reservas .order-actions button {
        margin-left: 0;
    }

    .admin-page.admin-reservas .order-details > div:first-child, .admin-page.admin-reservas .order-details .columnas > div {
        margin-right: unset;
    }
}