.encuesta-satisfaccion {
    padding-top: 31px;
    padding-bottom: 150px;
}

.encuesta-satisfaccion > div:not(:first-child()) {
    margin-top: 4rem;
}

.encuesta-satisfaccion .error h2 {
    padding: 30vh 0 2rem;
    text-align: center;
}

.encuesta-satisfaccion .error a {
    display: block;
    margin: 0 auto;
}

.encuesta-satisfaccion .event-name {
    font-size: 1.5rem;
    text-transform: uppercase;
    margin: 0;
}

.encuesta-satisfaccion .negocio-name {
    margin: 0 0 1rem;
    font-weight: bold;
    font-size: 1.2rem;
}

.encuesta-satisfaccion .container {
    padding-left: 25px;
    padding-right: 25px;
    max-width: 640px;
    margin: 0 auto;
}

.encuesta-satisfaccion .categories h3 {
    font-size: 1.5em;
    margin-top: 1em;
}

.encuesta-satisfaccion .categories h3:first-of-type {
    margin-top: 2em;
}

.encuesta-satisfaccion .categories {
    display: grid;
    grid-template-columns: 2fr 2fr;
}

.encuesta-satisfaccion .comentarios {
    margin-top: 4em;
}

.encuesta-satisfaccion .save-button {
    width: 100%;
    margin-top: 1em;
}

.encuesta-satisfaccion .event-image {
    border: none;
    margin-bottom: 2em;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.encuesta-satisfaccion > div:not(:first-of-type) .event-image {
    margin-top: 4rem;
}

.encuesta-satisfaccion .save-button-container {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 10px 24vw 25px;
    background: white;
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    border-top: 1px solid lightgrey;
    max-width: unset;
    display: flex;
    justify-content: center;
}

.encuesta-satisfaccion .custom-field textarea {
    border-color: grey;
}

.star-meter {
    display: flex;
    align-items: center;
}

.star-meter.static .material-symbols-rounded.clickable {
    cursor: default;
}

.star-meter > span {
    font-size: 3em;
}

.star-meter > span.active {
    color: var(--resaltado);
}

.star-meter > span:not(.active) {
    opacity: 0.5;
}

.star-meter .numeric-value {
    background-color: var(--resaltado);
    color: white;
    font-weight: bold;
    font-size: 13px;
    padding: 0 5px;
    border-radius: 3px;
    display: inline-block;
    margin-left: 7px;
}

@media(max-width: 992px) {
    .encuesta-satisfaccion {
        padding-top: 0;
    }

    .star-meter > span {
        font-size: 3em;
    }

    .encuesta-satisfaccion .categories {
        grid-template-columns: 1fr;
    }
}
