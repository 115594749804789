.tab-menu {
    margin-bottom: 2em;
}

.tab-menu .links {
    padding-bottom: 9px;
    border-bottom: 3px solid #fbf6f2;
    margin-bottom: 1em;
}

.tab-menu .links a {
    padding: 0 1em 12px 1em;
    border-bottom: 3px solid transparent;
    transition: 300ms;
    font-weight: 500;
    color: grey;
}

.tab-menu .links a:hover {
    border-bottom-color: #dddd;
}

.tab-menu .links a.active {
    border-bottom-color: #333;
    color: #333;
}

.tab-menu .custom-elements {
    display: inline-block;
}

@media(max-width: 1199px) {
    .tab-menu .links {
        font-size: 0.9rem;
    }
}