.video-upload {
	position: relative;
	width: 100%;
	height: 100%;
}

.video-upload.empty {
	align-items: center;
	background-color: #f6f6f6;
	border: 1px solid #d3d3d3;
	border-radius: 3px;
	display: flex;
	justify-content: center;
	min-height: 300px;
	cursor: pointer;
}

.video-upload span {
	pointer-events: none;
}

.video-upload .overlay {
	opacity: 0;
	pointer-events: none;
	position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: black;
    transition: 300ms;
}

.video-upload.empty:hover .overlay {
	opacity: 0.75;
}

.video-upload.empty:hover .add-icon,
.video-upload.empty:hover .max-upload-size {
	transition: 300ms;
	color: black;
	filter: invert(1);
	z-index: 2;
	user-select: none;
}

.video-upload .max-upload-size {
	pointer-events: none;
	position: absolute;
	bottom: 15px;
	width: 100%;
	text-align: center;
}

.video-upload video {
	max-width: 100%;
	height: 100%;
}

.video-upload .upload-controls {
	display: none;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: black;
	border-radius: 5px;
	padding: 5px 5px 0;
}

.video-upload:not(.empty) .add-icon {
	display: none;
	color: white;
}

.video-upload:hover .upload-controls {
	display: inline-flex;
	justify-content: center;
	align-items: center;
}

.video-upload:hover .add-icon {
	display: block;
}
