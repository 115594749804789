.floating-tooltip {
	pointer-events: none;
	position: fixed;
	font-size: 13px;
	background-color: var(--gris-texto);
	color: white;
	font-weight: bold;
	display: flex;
	align-items: center;
	border-radius: 3px;
	z-index: 3000;
	padding: 5px 15px;
	white-space: nowrap;
	animation: tooltip-appear 300ms forwards;
}

@keyframes tooltip-appear {
	0% {
		opacity: 0;
		transform: translateY(-10px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
