@media(max-width: 992px) {
    .admin-page.admin-usuarios .table-component .table-row > span {
        padding: 0 5px;
    }

    .admin-page.admin-usuarios .table-component {
        margin: 20px 10px;
    }

    .admin-page .table-component .table-row > span {
        overflow: hidden;
    }

    .admin-page.admin-usuarios .row-details > div {
        display: flex;
        flex-direction: column;
    }

    .admin-page.admin-usuarios .kpi-container {
        display: flex;
        flex-direction: column;
    }

    .admin-page.admin-usuarios .chart-container {
        height: 20vh;
    }

    .admin-page.admin-usuarios .row-details > div.row-estadisticas .columna {
        min-height: 20vh;
    }
}