.admin-bloque {
    border-top: 1px solid lightgrey;
	padding: 25px;
    display: flex;
    flex-direction: column;
}

.admin .header + .admin-bloque {
	border-top: none;
}

.admin-bar + .admin-bloque,
.admin-bloque:first-of-type {
	border-top: none;
	padding-top: 25px;
}

.admin-bloque.columnas {
    display: grid;
    align-items: flex-start;
    gap: 25px;
    background-color: #f6f6f6;
}

.admin-bloque.columnas.invisibles {
	background-color: white;
}

.admin-bloque.columnas .columna {
    padding: 20px;
    background: white;
    border-radius: 10px;
    border: 1px solid lightgrey;
}

.admin-bloque.columnas.invisibles .columna {
	border: none;
}

.admin-bloque.filas {
    display: flex;
	flex-direction: column;
    background-color: #f6f6f6;
}

.admin-bloque.filas .fila {
    padding: 20px;
	margin-bottom: 25px;
    background: white;
    border-radius: 10px;
    border: 1px solid lightgrey;
}

.admin-bloque.filas .fila:last-child {
	margin-bottom: 0;
}

@media(max-width: 992px) {
    .admin-bloque.columnas .columna {
        padding: 10px;
    }

    .admin-bloque.columnas {
        gap: 10px;
    }

    .admin-bloque {
        padding: 10px;
    }
}