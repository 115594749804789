.imagen-paso {
	background-size: cover 100%;
	background-position: center;
	background-repeat: no-repeat;
	min-height: 600px;
}

.imagen-paso img {
	width: 100%;
}

@media(max-width: 992px) {
	.imagen-paso {
		min-height: unset;
	}
}