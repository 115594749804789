.editor-slider .slides-container {
    overflow-x: hidden;
    position: relative;
}

.editor-slider .slides-container .inner {
    display: flex;
    transition: left 300ms;
}

.editor-slider .slide {
    position: relative;
}

.editor-slider .slide .no-slider-placeholder {
    display: flex;
    justify-content: center;
}

.editor-slider .slide .overlay {
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: #00000036;
}

.editor-slider .slide img {
    height: 40vh;
    width: auto;
}

.editor-slider .minimap {
    position: absolute;
    bottom: 35px;
    left: 52%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0;
    background: #ffffffaa;
    z-index: 1;
    border-radius: 10px;
}

.editor-slider .minimap:hover {
    opacity: 1;
}

.editor-slider .minimap .thumbnail {
    cursor: pointer;
    display: block;
    background-position: center;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    width: 48px;
    border-radius: 3px;
    margin-left: 5px;
    height: 48px;
    filter: grayscale(1);
    transition: filter 300ms;
}

.editor-slider .minimap .thumbnail:last-child {
    margin-right: 5px;
}

.editor-slider .minimap .thumbnail .delete-button {
    position: absolute;
    right: -4px;
    top: -3px;
    background: white;
    border-radius: 8px;
    width: 14px;
    height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
}

.editor-slider .minimap .thumbnail .delete-button span {
    transform: scale(0.5);
}

.editor-slider .minimap .thumbnail:hover .delete-button {
    opacity: 1;
    pointer-events: all;
}

.editor-slider .minimap .thumbnail:hover
.editor-slider .minimap .thumbnail.active {
    filter: grayscale(0);
}

.editor-slider .new-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0;
    margin-right: 5px;
    width: 48px;
    height: 48px;
}

.editor-slider .custom-field.title {
    position: absolute;
    z-index: 1;
    top: 11%;
    left: 4%;
    width: 89%;
}

.editor-slider .custom-field.title input {
    font-size: 50px;
    font-family: Circular;
    font-weight: bold;
    background: transparent;
    text-transform: uppercase;
    color: white;
}

.editor-slider .custom-field.subtitle {
    position: absolute;
    z-index: 1;
    top: calc(11% + 80px);
    left: 4%;
    width: 89%;
}

.editor-slider .custom-field.subtitle input {
    font-size: 22px;
    font-family: Circular;
    font-weight: 400;
    background: transparent;
    text-transform: uppercase;
    color: white;
}

.editor-slider .color-toggle {
    position: absolute;
    background: white;
    border-radius: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 23px;
    top: 15px;
    right: 15px;
    z-index: 1;
}

.editor-slider .color-toggle > div {
	display: flex;
	justify-content: center;
	align-items: center;
}

.editor-slider .color-toggle.color-white {
	filter: invert(1);
}

.editor-slider .color-toggle .material-symbols-rounded {
	font-size: 32px;
}

.editor-slider .cta-button {
    position: absolute;
    top: 48%;
    background: white;
    padding: 21px;
    font-size: 15px;
    font-family: 'Circular';
    text-transform: uppercase;
    font-weight: 700;
    left: 4%;
    z-index: 2;
    border-radius: 3px;
}
