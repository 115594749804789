.venue-extras {
	position: relative;
	max-width: 1300px;
	padding: 0 20px 110px 20px;
	margin: 0 auto;
}

.venue-extras .main {
	width: calc(70% - 25px);
	margin-right: 30%;
}

.venue-extras .sidebar {
	float: right;
	margin-top: 25px;
	width: 30%;
}

@media (min-width: 993px) {
	.venue-extras .sidebar .inner {
		position: fixed;
		width: 28.7vw;
		padding-bottom: 25px;
		transition: top 300ms;
	}
}

.venue-extras .sidebar .image {
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	height: 133px;
	border-radius: 3px 3px 0 0;
	margin-top: -1em;
}

.venue-extras .sidebar .negocio-name {
	font-weight: 700;
	text-transform: uppercase;
	margin-top: 1em;
}

.venue-extras .sidebar .negocio-address {
	font-size: 13px;
	text-transform: uppercase;
}

.venue-extras .sidebar .booking-information {
	margin-top: 1em;
	padding-top: 1em;
	border-top: 1px solid lightgrey;
	margin-left: -1em;
	margin-right: -1em;
	padding-left: 1em;
	padding-right: 1em;
}

.venue-extras .sidebar .booking-information .details-line {
	display: flex;
	align-items: center;
}

.venue-extras .sidebar .booking-information .details-line > span:first-child {
	margin-right: 5px;
}

.venue-extras .sidebar .booking-information .details-line.availability-description {
	font-size: 13px;
	font-weight: 700;
	margin-bottom: 5px;
	margin-left: 28px;
	text-transform: uppercase;
}

.venue-extras .sidebar .included-pax-count {
	color: white;
	background: var(--resaltado);
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 20px;
	height: 20px;
	border-radius: 10px;
	font-size: 0.9em;
	font-weight: bold;
	margin-left: 2px;
}

.venue-extras .sidebar .change-order-link {
	margin-top: 0.5em;
}

.venue-extras .sidebar .boton-confirmar {
	width: 100%;
	margin-top: 1em;
	display: flex;
	justify-content: center;
}

.venue-extras .main > .categorias a {
	display: inline-block;
	padding: 0.5em 1em;
	border-bottom: 2px solid #d3d3d3;
	text-align: center;
	font-weight: 700;
}

.venue-extras .main > .categorias a.active {
	border-bottom-color: var(--gris-texto);
}

body.scrolled .venue-extras .main {
	padding-top: 45px;
}

body.scrolled .venue-extras .main > .categorias .inner {
	position: fixed;
	background: white;
	z-index: 1020;
}

.venue-extras .intro-extras {
	padding: 1em 0;
}

.venue-extras h2 {
	margin-bottom: 0;
}

.venue-extras .extras .category {
	margin-bottom: 2em;
}

.venue-extras .extras .category > .description {
	font-size: 14px;
	color: #666;
	margin-bottom: 15px;
}

.venue-extras .extras .category-extras {
	display: grid;
	grid-template-columns: 2fr 2fr;
	margin: 0.5em 0;
	gap: 1em;
}

.venue-extras .extras .category-extras .extra {
	color: #666;
	cursor: pointer;
	margin-bottom: 2px;
	position: relative;
	border-radius: 30px;
	box-shadow: 0 0px 15px rgb(0 0 0 / 10%);
	border: none;
	padding: 10px;
}

.venue-extras .extras .category-extras .extra .image {
	float: right;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 135px;
	margin-bottom: auto;
	margin-left: 0.75em;
	margin-top: 0;
	width: 135px;
	border-radius: 20px;
}

.venue-extras .extras .category-extras .extra h3 {
	margin-bottom: 0;
	font-size: 16px;
	line-height: 1.3;
	width: 65%;
	margin: 5px 0 0 5px;
}

.venue-extras .extras .category-extras .extra .description {
	font-size: 13px;
	line-height: 1.3;
	margin: 1em 25px 0 5px;
}

.venue-extras .extras .category-extras .extra .description p {
	text-overflow: ellipsis;
	-webkit-line-clamp: 4;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.venue-extras .extras .category-extras .extra .price-tag {
	align-items: center;
	top: 15px;
	right: 15px;
	display: inline-flex;
	justify-content: space-evenly;
	margin: 0;
	position: absolute;
	text-transform: uppercase;
	background: white;
	border: 2px solid var(--resaltado-bordes);
	border-radius: 20px;
	color: var(--gris-texto);
	font-weight: 100;
	font-size: 13px;
	padding: 3px 13px;
}

.venue-extras .extras-modal .image {
	height: min(300px, 50vh);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.venue-extras .extras-modal .description {
	padding: 2em;
	border-bottom: 1px solid lightgrey;
}

.venue-extras .extras-modal .quantity-selector {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: 2em;
	padding-left: calc(70px - 2em);
}

.venue-extras .extras-modal .quantity-selector .quantity-button {
	font-size: 28px;
	padding: 5px;
	border-radius: 17px;
	box-sizing: border-box;
	background-color: transparent;
}

.venue-extras .extras-modal .quantity-selector .quantity-button:hover {
	background-color: #f6f6f6;
}

.venue-extras .extras-modal .quantity-selector .quantity {
	background: var(--resaltado);
	border-radius: 20px;
	color: #fff;
	font-size: 16px;
	font-weight: 700;
	padding: 0.5em 1em;
	margin: 0 1em;
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.venue-extras .extras-modal .controls {
	padding: 0 2em 2em 2em;
	display: flex;
	justify-content: center;
}

.venue-extras .extras-modal .controls .confirm {
	width: 300px;
	justify-content: space-between;
	display: flex;
	padding: 24px;
	margin-left: 70px;
}

.venue-extras .category-extras .extra .quantity {
	background: var(--resaltado);
	color: #fff;
	font-weight: 700;
	position: absolute;
	right: -5px;
	top: -5px;
	width: 32px;
	height: 32px;
	border-radius: 16px;
	font-size: 13px;
	justify-content: center;
	align-items: center;
	display: flex;
	z-index: 1;
}

.venue-extras .sidebar .ver-detalle {
	display: none;
}

@media (max-width: 992px) {
	.venue-extras .main {
		width: 100%;
		margin: 1em 0;
		padding-top: 35px;
	}

	body .venue-extras .main > .categorias {
		position: fixed;
		left: 0;
		right: 0;
		z-index: 1;
		scrollbar-width: 0;
	}

	body .venue-extras .main > .categorias::-webkit-scrollbar {
		display: none;
	}

	body.scrolled .venue-extras .main > .categorias .inner {
		position: unset;
	}

	.venue-extras .sidebar {
		position: fixed;
		width: 100%;
		margin: 0;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 1021;
		background: transparent;
		overflow: hidden;
		pointer-events: none;
		height: calc(100% + 1px);
	}

	.venue-extras .sidebar .inner {
		pointer-events: all;
		background: white;
		height: 100%;
		padding: 1em;
		border-top: 1px solid lightgrey;
		transition: 300ms;
		left: 0;
		right: 0;
		position: absolute;
	}

	.venue-extras .sidebar.active .inner {
		top: -1px;
		overflow: auto;
	}

	.venue-extras .sidebar .inner .box {
		display: none;
	}

	.venue-extras .sidebar.active .inner .box {
		display: block;
		margin-top: 1em;
	}

	.venue-extras .sidebar .boton-confirmar {
		border-radius: 3px;
		position: absolute;
		top: 40px;
		left: 15px;
		right: 15px;
		width: unset;
	}

	.venue-extras .sidebar.active .boton-confirmar {
		position: relative;
		top: unset;
		bottom: 0;
		left: 0;
	}

	.venue-extras .sidebar .boton-confirmar-container {
		width: 100%;
		background: white;
		position: absolute;
		top: 35px;
		right: 0;
		left: 0;
		height: 95px;
	}

	.venue-extras .sidebar.active .boton-confirmar-container {
		bottom: unset;
		top: unset;
	}

	.venue-extras .sidebar .ver-detalle {
		display: block;
		margin: 0 auto;
		text-align: center;
	}

	.venue-extras .sidebar .ver-detalle img {
		height: 10px;
		margin-left: 0.75em;
		transform: rotate(180deg);
	}

	.venue-extras .sidebar.active .ver-detalle img {
		transform: rotate(0deg);
	}

	.venue-extras .extras .category-extras {
		display: flex;
		flex-direction: column;
	}

	.venue-extras .extras .category-extras .extra h3 {
		line-height: 1;
		margin-bottom: 5px;
	}

	.venue-extras .extras .category-extras .extra .price-tag {
		top: 15px;
		bottom: unset;
	}

	.venue-extras .extras .category-extras .extra .image {
		position: absolute;
		right: 10px;
		top: 10px;
		width: 135px;
		height: 135px;
	}

	.venue-extras .extras .category-extras .extra {
		padding-right: calc(100px + 2em);
		height: 155px;
	}

	.venue-extras .extras-modal {
		padding-bottom: 60px;
	}

	.venue-extras .extras-modal .description p:last-child {
		margin-bottom: 0;
	}

	.venue-extras .extras-modal .quantity-selector {
		padding: 0;
		margin-top: 1em;
	}

	.venue-extras .extras-modal .button-confirm-container {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background: white;
		padding: 15px;
	}

	.venue-extras .extras-modal .controls .confirm {
		width: 100%;
		padding: 0 2em;
		margin: 0;
	}

	.venue-extras .extras-modal .controls .confirm span {
		margin-left: auto;
	}
}
