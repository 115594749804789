.pago {
    padding-bottom: 25px;
}

.box {
    padding: 1em;
    border: 1px solid #d3d3d8;
    border-radius: 3px;
}

.booking-sidebar {
    position: -webkit-sticky; /* for safari */
    position: sticky;
    top: 150px;
    margin-bottom: auto;
}

.pago > .container > .row {
    position: relative;
}

.pago .booking-sidebar {
    position: absolute;
    width: 333px;
    right: 0;
    top: 24px;
    margin-bottom: auto;
}

.pago .alter-pax-button {
    font-size: 17px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 26px;
    border-radius: 14px;
}

.pago .alter-pax-button:hover {
    background-color: #ededed;
}

.event-image {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 200px;
    margin-top: 5px;
    border-bottom: 1px solid lightgrey;
    border-top: 1px solid lightgrey;
}

.event-image:first-child {
    border-top: none;
    margin-top: 0;
}

.booking-sidebar .booking-information {
    margin-top: 16px;
}

.booking-sidebar .aplicar-codigo-descuento {
    margin-top: 10px;
    padding: 10px;
}

.pago .booking-sidebar .event-information {
    padding-top: 0;
}

.pago h1 {
    color: var(--gris-texto);
    font-weight: 700;
    font-family: Circular;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-top: 75px;
}

.pago h2 {
    margin-bottom: 25px;
    font-size: 1.1em;
    font-weight: 400;
    color: #5c5c5c;
    font-family: Circular, sans-serif;
}

.pago h4 {
    color: #5c5c5c;
    font-weight: 400;
    font-size: 18px;
    margin: 5px 0 0 0;
    line-height: 1;
}

.pago h4:first-of-type {
    margin-top: 10px;
}

.pago .container {
    max-width: 1000px;
    margin-bottom: 150px;
}

.pago .order-details {
    padding-top: 1em;
}

.pago .order-details > div.order-details-preorder {
    margin: 0 -16px 0 -16px;
    padding: 0 16px 0 16px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.pago .preorder-details-line {
    display: flex;
    width: 100%;;
    justify-content: space-between;
    margin-bottom: 5px;
}

.pago .preorder-details-line.secondary {
    font-size: 16px;
    margin-bottom: 0;
}

.pago .preorder-details-line.secondary .preorder-name {
    font-size: 16px;
}

.pago .preorder-details-line.secondary.details {
    margin-bottom: 12px;
    padding-left: 0.1em;
    justify-content: flex-start;
    align-items: center;
}

.pago .preorder-details-line.secondary.details + .box-alergia + .preorder-details-line.secondary:not(.details),
.pago .preorder-details-line.secondary.details + .preorder-details-line.secondary:not(.details) {
    margin-top: 0.5em;
}

.pago .preorder-details-line.secondary + .box-alergia + .preorder-details-line:not(.secondary),
.pago .preorder-details-line.secondary + .preorder-details-line:not(.secondary) {
    margin-top: 1em;
    padding-top: 1em;
    border-top: 1px solid lightgrey;
}

.pago .preorder-details-line .preorder-quantity {
    background: var(--resaltado);
    text-align: center;
    color: white;
    border-radius: 5px;
    padding: 0.5em 1em;
    font-weight: bold;
    font-size: 13px;
    margin-right: 1em;
    margin-bottom: auto;
}

.pago .preorder-details-line.secondary .preorder-quantity {
    background: transparent;
    background-color: var(--resaltado);
    color: white;
    padding: 0.25em 0.5em;
    font-size: 11px;
    margin-bottom: auto;
    margin-top: auto;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-width: 28px;
    height: 28px;
    border-radius: 14px;
}

.pago .preorder-details-line .preorder-name {
    margin-top: -3px;
    padding-bottom: 5px;
    line-height: 1.3;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: auto;
    margin-bottom: 2px;
    font-size: 17px;
    width: 100%;
}

.pago .preorder-details-line .preorder-name .preorder-name-line {
    display: flex;
}

.pago .preorder-details-line .details-address {
    color: grey;
    font-size: 16px;
    margin-bottom: 5px;
}

.pago .preorder-details-line .preorder-event-name {
    display: flex;
    justify-content: space-between;
}

.pago .preorder-details-line .preorder-amount {
    margin-left: auto;
    font-weight: normal;
    width: 4em;
    text-align: right;
}

.pago .preorder-details-line .event-name {
    display: flex;
}

.pago .preorder-details-line .negocio-name {
    font-weight: 700;
    margin-top: 10px;
    text-transform: uppercase;
}

.pago .preorder-details-line.title {
    flex-direction: column;
}

.pago .order-details > div {
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;
}

.pago .order-details > div.order-details-total {
    border-radius: 0 0 3px 3px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border-top: 1px solid lightgrey;
    margin: 16px -16px 0;
}

.pago .order-details-total .pax {
    margin-right: auto;
}

.pago .order-details-total .pax-selector {
    align-items: center;
    border-bottom: 1px solid #d3d3d3;
    display: flex;
    flex: 1;
    padding: 15px;
}

.pago .order-details-total .pax-button {
    align-items: center;
    background: #fff;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 30px;
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    width: 40px;
}

.pago .order-details-total .pax-button span {
    font-size: 32px;
}

.pago .order-details-total .total {
    align-items: center;
    display: flex;
    flex: 1;
    font-weight: 700;
    justify-content: space-between;
    padding: 15px;
    text-transform: uppercase;
}

.pago .order-container {
    margin-right: 1em;
    margin-top: 24px;
    max-width: calc(66.66% - 1em);
}

.pago h3 {
    font-size: 1rem;
    margin-top: 2em;
}

.pago .order-type {
    font-size: 14px;
    line-height: 1.3;
    color: var(--gris-texto);
}

.box h3 {
    font-size: 1.75rem;
    margin: 0;
    text-transform: uppercase;
}

.pago .box.payment-details {
    background: transparent;
    border: none;
    padding: 0.5em 0 0 0;
}

.pago .box.payment-details input {
    background: white;
}

.pago .fecha-caducidad-container input,
.pago .cvc-container input {
    margin-top: 2px;
}

.pago .fecha-caducidad-container .validation-message,
.pago .cvc-container .validation-message {
    position: absolute;
}

.pago .fecha-caducidad-container label,
.pago .cvc-container label {
    margin-top: 2px;
}

.box + label {
    margin: 2em 0;
    font-size: 14px;
}

.pago .disclaimer a {
    text-decoration: underline;
}

.pago .disclaimer {
    margin-top: 2em;
    color: #999;
}

.pago .btn {
    width: 100%;
}

.pago .btn-pagar {
    margin-top: 30px;
    height: 58px;
}

.pago .accept-terms-error {
    font-size: 0.9em;
    color: crimson;
    margin: 0.5em 0;
    text-align: center;
}

.pago .btn:hover {
    box-shadow: -650px 0 0 #fbf6f2 inset;
    -webkit-box-shadow: -650px 0 0 #fbf6f2 inset;
}

.pago .formas-pago {
    display: block;
    margin: 2em auto;
    height: 25px;
}

.pago footer .container {
    max-width: 100%;
}

.pago textarea {
    width: 100%;
    padding: 0.5em;
    border: 1px solid #d3d3d8;
}

.order-completed-notice {
    padding: 0;
    border: 1px solid #d3d3d8;
    text-align: center;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    color: green;
    opacity: 0;
    animation: 300ms ease-in-out forwards order-completed-fadein;
}

@keyframes order-completed-fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.order-completed-notice h2,
.order-completed-notice h3 {
    margin: 0 auto;
}

.change-order-link {
    width: 100%;
}

.order-details-preorder .change-order-link {
    padding-top: 1em;
    margin-top: 1em;
    border-top: 1px solid lightgrey;
}

.change-order-link a {
    font-size: 0.9em;
    text-decoration: underline;
}

.pago .box-alergia {
    margin-top: 1.5em;
    font-size: 14px;
    width: 100%;
}

.pago .order-completed-notice {
    font-size: 3em;
}

.box > div:first-of-type,
.box .event-image {
    margin-left: -16px;
    margin-right: -16px;
    padding: 0 15px;
    padding-bottom: 16px;
    margin-bottom: 0;
    border-bottom: 1px solid lightgrey;
}

.box > div:first-of-type.slider {
    padding-left: 0;
}

.pago .box .slider h1 {
    color: white !important;
    font-size: 11px;
    top: 10px;
    position: absolute;
    right: 10px;
    margin: 0;
    background: var(--resaltado);
    padding: 5px 10px;
    border-radius: 5px;
    line-height: 1;
}

.pago .box.caja-descuento {
    font-size: 15px;
    margin-top: 25px;
    text-align: center;
}

.pago .caja-descuento input {
    border-radius: 0;
    background: white;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none;
    outline: none;
    padding: 6px 8px;
    width: 100%;
    border: 1px solid #d3d3d8;
    font-size: 16px;
    margin: 0;
    text-align: center;
    display: block;
    min-width: 0;
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;
}

.pago .box.caja-descuento > div:first-of-type {
    border: none;
    font-size: 14px;
}

.etiqueta-stripe {
    display: flex;
    float: right;
    margin-top: 35px;
    padding-bottom: 20px;
}

.etiqueta-stripe img {
    height: 1.5em;
    margin-left: 10px;
    position: relative;
}

.popup-stripe {
    position: absolute;
    font-size: 13px;
    right: 0;
    line-height: 1.3;
    border: 1px solid lightgrey;
    background: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: -1;
    opacity: 0;
    padding: 1em;
    border-radius: 0.5em;
    margin-top: 23px;
    transition: 300ms;
    transition-timing-function: ease-out;
    max-width: 300px;
}

.etiqueta-stripe:hover .popup-stripe {
    z-index: 1;
    opacity: 1;
    margin-top: 33px;
}

.popup-stripe h2 {
    color: green;
    font-weight: bold;
    font-size: 13px;
    line-height: 1;
    margin-bottom: 0.5em;
}

.popup-stripe a  {
    color: #635bff;
}

.etiqueta-stripe i {
    font-size: 1.2em;
    color: #635bff;
    cursor: pointer;
}

.titular-datos-pago + p {
    font-size: 14px;
    margin-block-end: 0;
}

.titular-datos-pago i {
    font-size: 1.1em;
}

.titular-datos-pago span {
    opacity: 0.75;
    margin-left: 8px;
}

.subtitulo-pago {
    font-size: 14px;
    color: grey;
    margin-top: 10px;
    margin-bottom: 40px;
}

.error-codigo-descuento {
    position: absolute;
    background: white;
    z-index: 3;
    width: calc(100% - 62px);
    text-align: center;
    margin-top: 66px;
    opacity: 1;
    animation-name: fade-in-down;
    animation-duration: 500ms;
    animation-iteration-count: 1;
    color: crimson;
}

.pago .subscription-type-info {
    display: flex;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
}

.pago .subscription-type-info .subscription-type-name {
    align-items: center;
    background: #fff;
    border: 2px solid var(--resaltado-bordes);
    border-radius: 20px;
    color: var(--gris-texto);
    display: inline-flex;
    font-weight: 100;
    justify-content: space-evenly;
    margin-top: 5px;
    padding: 0.25em 1em;
    font-size: 13px;
}

.pago .subscription-type-info .subscription-type-pack {
    align-items: center;
    background: var(--resaltado);
    border-radius: 20px;
    color: white;
    text-transform: uppercase;
    display: inline-flex;
    font-weight: 100;
    justify-content: space-evenly;
    margin-top: 5px;
    margin-left: 1em;
    padding: 0.25em 1em;
    font-size: 13px;
}

.pago .producto-tag {
    align-items: center;
    background: var(--resaltado);
    border-radius: 20px;
    color: white;
    text-transform: uppercase;
    display: inline-flex;
    font-weight: 100;
    justify-content: space-evenly;
    margin-top: 0;
    margin-left: 0;
    padding: 0.25em 1em;
    font-size: 11px;
}

@keyframes fade-in-down {
    0% {
        opacity: 0;
        margin-top: 60px;
    }
    100% {
        opacity: 1;
        margin-top: 66px;
    }
}

@media (max-width: 991px) {
    .pago h1 {
        margin-top: 1em;
        font-size: 24px;
        font-weight: bold;
        text-align: left;
        text-transform: none;
        margin-bottom: 0;
    }

    .pago h2 {
        margin-bottom: 25px;
        font-size: 13px;
        line-height: 16px;
        padding: 0;
    }

    .pago .order-container {
        max-width: 100%;
        margin-right: 0;
    }

    .booking-sidebar {
        display: none;
    }

    .pago .container {
        margin-bottom: 60px;
    }

    .box h3 {
        margin-bottom: 2px;
        font-size: 1.25rem;
    }

    .pago h4 {
        font-size: 14px;
    }

    .box > div > div {
        font-size: 14px;
    }

    .pago .order-details > .details-line {
        height: 25px;
    }

    .pago .box-alergia + h3 {
        margin-top: 1em;
    }

    .box + label {
        font-size: 13px;
        display: flex;
        align-items: center;
    }

    .box + label input {
        margin-right: 0.5em;
    }

    .pago .disclaimer {
        font-size: 13px;
    }

    .pago .order-completed-notice {
        padding: 0;
    }

    .subtitulo-pago + label {
        font-size: 14px;
    }

    .titular-datos-pago span {
        display: none;
    }

    .etiqueta-stripe {
        padding-left: 0;
    }

    .etiqueta-stripe i {
        font-size: 1.2em;
    }

    .pago .box.caja-descuento {
        margin-top: 15px;
    }

    .pago .box.caja-descuento > div:first-of-type {
        font-size: 14px;
        padding-left: 16px;
        text-align: left;
    }

    .pago .caja-descuento input {
        padding: 10px 8px;
    }

    .pago .box.caja-descuento .btn.btn-brown {
        height: 45px;
        margin-top: 10px;
    }

    .pago .formas-pago {
        display: block;
        margin-top: 37px;
        height: 25px;
        margin: 37px auto 0;
        float: none;
    }

    .pago .preorder-details-line .preorder-name {
        font-size: 15px;
        max-width: calc(100% - 50px);
    }

    .pago .preorder-details-line .details-address {
        font-size: 14px;
    }

    .pago .preorder-details-line.title .preorder-amount {
        line-height: 1.2;
    }

    .pago .preorder-details-line.secondary.details {        
        font-size: 16px;
    }

    .pago .preorder-details-line.secondary,
    .pago .preorder-details-line.secondary .preorder-name {
        font-size: 14px;
    }

    .pago .preorder-details-line.secondary .preorder-quantity {
        width: 25px;
        height: 25px;
    }

    .pago .preorder-details-line.title + .change-order-link {
        padding-bottom: 1em;
        margin-bottom: 1em;
        border-bottom: 1px solid lightgrey;
        padding-top: 0;
        margin-top: 0;
        border-top: none;
    }

    .pago .box h3 {
        margin-bottom: 0;
        font-size: 18px;
    }

    .pago .order-container {
        margin-top: 18px;
    }
}
