.login h1 {
	color: var(--gris-texto);
	font-weight: 700;
	font-family: Circular;
	font-size: 20px;
	line-height: 32px;
	letter-spacing: 1px;
	text-transform: uppercase;
	margin-top: 75px;
	margin-bottom: 1em;
}

.login .subtitle {
	font-size: 18px;
	font-weight: 400;
	line-height: 30px;
	text-align: center;
	letter-spacing: 1px;
	max-width: 610px;
}

.login .form-container {
	max-width: 400px;
	margin-left: auto;
	margin-right: auto;
}

.login .btn.btn-brown {
	margin: 25px 0 10px;
	width: 100%;
}

.login form > div:last-child {
	margin-bottom: 50px;
}

.login .error-message {
	padding-top: 1em;
	color: var(--error);
}

.login .form-input input {
	border-radius: 0;
	background: white;
}

.login a {
	text-decoration: underline;
}

@media(max-width: 991px) {
	.login .btn.btn-brown ~ div {
		font-size: 14px;
	}
}