.payment-method-card {
    position: relative;
    padding: 20px;
    border: 1px solid #ededed;
    background-color: #fafafa;
    border-radius: 3px;
    height: 140px;
}

.payment-method-card .brand {
    position: absolute;
    bottom: 10px;
    right: 20px;
    opacity: 0.5;
    font-weight: bold;
    font-size: 1.5em;
}

.payment-method-card .expiration-date {
    position: absolute;
    bottom: 10px;
    left: 20px;
    opacity: 0.8;
    font-size: 0.8em;
}

.payment-method-card .number {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 1.3em;
}