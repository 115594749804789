.top-list {
	padding-top: 80px;
	transition: opacity 150ms;
}

.home .search-results-container {
	max-width: 1200px;
}

.btn.btn-ver-todos {
	border-radius: 0px;
	border: 1px solid black;
	font-size: 0.8rem;
	padding: 1.25rem 1.25rem;
	line-height: 1.25;
}

.btn.btn-ver-todos:hover {
	box-shadow: none;
	background-color: #fbf6f2;
}

.home section.top-list {
	padding-bottom: 0;
}

.home section.steps .step.row {
	padding: 80px 0;
}

.home section.steps .step.row:nth-child(1) {
	padding-left: 60px;
	background-color: var(--fondo);
}

.home section.steps .step.row:nth-child(3) {
	padding-right: 60px;
}

section.steps .steps-inner {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	height: 100%;
	padding: 0 60px;
}

section.steps .steps-inner .col-4 {
	display: flex;
	align-items: center;
}

section.steps p,
section.steps .p {
	max-width: 90%;
	color: #5c5c5c;
	font-weight: 400;
	margin: 1.5rem 0;
	font-family: Circular;
	font-size: 18px;
	line-height: 32px;
	letter-spacing: 1px;
}

section.steps .btn-brown {
	margin-top: 40px;
}

.home section.steps .step.row.step-video {
	padding: 0;
	overflow: hidden;
	width: 100%;
	height: 70vh;
	position: relative;
	margin: 40px 0;
}

.home section.steps .step.row.step-video .steps-inner {
	padding: 0;
}

section.steps .step-video video {
	width: 100%;
	height: auto;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

section.steps .steps-3-logo {
	width: 126px;
	height: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 0.5rem;
	padding: 0.75rem;
	margin-bottom: 1em;
}

section.steps .steps-3-logo img {
	width: auto;
}

section.steps>.container.full-width {
	padding-bottom: 0;
}

.review-quote {
	padding: 170px 0 80px;
	text-align: center;
}

.review-quote-text {
	font-family: Circular;
	font-size: 32px;
	line-height: 40px;
	letter-spacing: 1px;
	color: var(--gris-texto);
	margin-bottom: 20px;
}

.review-quote-logo {
	display: inline-block;
}

section.ciudades-spotnow {
	padding-top: 0;
	background: white;
}

.home h2 {
	text-transform: uppercase;
	font-size: 32px;
	margin-bottom: 26px;
}

.top-list h2 {
	font-weight: 700;
}

section.ciudades-spotnow h2 {
	font-weight: 700;
	margin-top: 1em;
	margin-bottom: -1em;
}

.section-title {
	font-family: Circular;
	font-weight: 400;
	color: var(--gris-texto);
	font-size: 44px;
	line-height: 48px;
	letter-spacing: 1px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.section-text {
	max-width: 560px;
	margin: 1.5rem 0;
	font-family: Circular;
	font-size: 18px;
	line-height: 32px;
	letter-spacing: 1px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-top: 65px;
	padding-bottom: 65px;
	color: #5c5c5ccc;
	font-weight: 400;
}

.welcome-banner.container.full-width {
	padding-bottom: 0;
}

.welcome-banner .steak-image {
	width: 90%;
	float: right;
	margin-top: 3em;
}

.home {
	position: relative;
}

.home .fork-knife {
	top: 6%;
	width: 23%;
	position: absolute;
	left: 22%;
	transition: none;
}

.welcome-banner .caption-text {
	float: left;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	flex-direction: column;
}

.welcome-banner h2 {
	max-width: 524px;
	margin: 1em 0 2em;
	width: 100%;
	color: var(--gris-texto);
	font-family: Circular;
	font-size: 18px;
	line-height: 32px;
	letter-spacing: 1px;
	font-weight: 400;
}

.bottom-banner {
	padding-bottom: 70px;
	border-bottom: 1px solid #dee2e6;
}

.bottom-banner .cake-image {
	max-width: 640px;
	margin-left: 50px;
	width: 100%;
	align-self: flex-end;
}

.bottom-banner h2 {
	font-family: Circular;
	color: var(--gris-texto);
	margin-top: 1rem;
	width: 100%;
	letter-spacing: 1px;
	margin-bottom: 0.5rem;
	font-weight: bold;
	text-transform: uppercase;
	max-width: 600px;
	font-size: 35px;
	line-height: 1.2;
}

.bottom-banner h3 {
	max-width: 542px;
	color: #5c5c5ccc;
	font-weight: 400;
	margin: 1.5rem 0 50px;
	font-family: Circular;
	font-size: 18px;
	line-height: 32px;
	letter-spacing: 1px;
}

.bottom-banner .row>div {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.bottom-banner a {
	color: #343a40;
}

.bottom-banner a:hover {
	color: #121416;
	text-decoration: none;
}

.home .swiper-container {
	height: 80vh;
}

.carrusel-main-eventos {
	display: grid;
	gap: 20px;
	margin-bottom: 25px;
}

.carrusel-main-evento {
	padding: 10px;
	border-radius: 50px;
	box-shadow: 0 0px 15px rgb(0 0 0 / 10%);
	transition: 500ms;
	border: 1px solid transparent;
	border-width: 1px 1px 5px 1px;
	cursor: pointer;
	margin-bottom: 15px;
	display: flex;
	flex-direction: column;
	opacity: 0;
	animation-name: carousel-item-container;
	animation-duration: 500ms;
	animation-fill-mode: forwards;
	animation-delay: 500ms;
	min-height: 410px;
}

.carrusel-main-evento.skeleton {
	cursor: default;
}

.carrusel-main-evento .image-container {
	border-radius: 40px;
	height: 60%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
}

.carrusel-main-evento.skeleton .image-container {
	background-image: linear-gradient(90deg, #ddd 0px, #ddd 50%, #ececec 60%, #ddd 70%);
	background-position-x: 100%;
	background-size: 300%;
	animation: shine 2s infinite ease-out;
}

.carrusel-main-evento.disabled .image-container {
	opacity: 0.5;
	filter: grayscale(1);
}

.carrusel-main-evento .icons {
	position: absolute;
	bottom: 5px;
	right: 5px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.carrusel-main-evento.skeleton .icons {
	display: none;
}

.carrusel-main-evento .icons .icon-image {
	height: 32px;
	width: 32px;
	margin-right: 8px;
	margin-bottom: 8px;
}

.carrusel-main-evento .icons .material-symbols-rounded.icon-image {
	background: white;
	border-radius: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.carrusel-main-evento .details {
	padding: 10px 15px 0 15px;
	flex: 1;
	display: flex;
	flex-direction: column;
	font-size: 13px;
	color: grey;
	background: white;
	border-radius: 0 0 5px 5px;
}

.carrusel-main-evento .city {
	text-transform: uppercase;
	margin-bottom: 10px;
}

.carrusel-main-evento.skeleton .city {
	opacity: 0;
	color: transparent;
	background-image: linear-gradient(90deg, #ddd 0px, #ddd 50%, #ececec 60%, #ddd 70%);
	background-position-x: 100%;
	background-size: 300%;
	border-radius: 4px;
	box-sizing: border-box;
	animation: shine 2s infinite ease-out;
}

.carrusel-main-evento .mid {
	margin-top: auto;
	position: relative;
	margin-bottom: 10px;
}

.carrusel-main-evento .mid>div {
	display: inline-block;
	margin-right: 10px;
}

.carrusel-main-evento .mid .icon-image {
	width: 32px;
	height: 32px;
	filter: grayscale(1);
	transition: filter 300ms;
}

.carrusel-main-evento:hover .mid .icon-image {
	filter: grayscale(0);
}

.carrusel-main-evento .lower {
	margin-left: -10px;
	margin-right: -10px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.carrusel-main-evento .minimum-price {
	padding: 7px 17px;
}

.carrusel-main-evento .minimum-price,
.carrusel-main-evento .average-rating {
	border: 2px solid var(--resaltado-bordes);
	background: white;
	color: var(--gris-texto);
	border-radius: 20px;
	font-weight: 100;
	font-size: 11px;
	text-transform: uppercase;
	display: inline-flex;
	justify-content: space-evenly;
	align-items: center;
	margin-top: 5px;
}

.carrusel-main-evento .average-rating {
	font-size: 12px;
	margin-right: 0;
	margin-left: 1em;
	padding: 5px 15px 5px 10px;
}

.carrusel-main-evento .average-rating span {
	color: grey;
	margin-top: -2px;
}

.carrusel-main-evento .minimum-price .number {
	font-size: 1.1em;
}

.carrusel-main-evento .name {
	font-size: 18px;
	font-weight: 600;
	color: #333;
	text-transform: uppercase;
}

.carrusel-main-evento.skeleton .name {
	opacity: 0;
	color: transparent;
	border-radius: 4px;
	border-bottom: 2px solid white;
	box-sizing: border-box;
	background-image: linear-gradient(90deg, #ddd 0px, #ddd 50%, #ececec 60%, #ddd 70%);
	background-position-x: 100%;
	background-size: 300%;
	animation: shine 2s infinite ease-out;
}

.home .banda-otros {
	padding-top: 25px;
	padding-bottom: 50px;
	margin-bottom: 50px;
	margin-top: 15px;
}

.home .banda-otros+.banda-otros {
	padding-top: 0;
	margin-top: -30px;
}

.home .banda-otros h2 {
	/* color: #333;
    font-size: 20px; */
	margin-bottom: 10px;
}

.home .banda-otros>.container {
	display: flex;
}

.home .banda-otros .info {
	align-self: flex-start;
	width: calc(20% + 45px);
	padding-right: 25px;
	font-size: 16px;
	color: grey;
	line-height: 1.3;
}

.home .banda-otros .container.carousel {
	padding-right: 0;
	margin-top: 35px;
}

.home .widget-filtro {
	user-select: none;
	position: absolute;
	opacity: 0;
	left: 50%;
	transform: translate(-50%, 0);
	background: white;
	padding: 0 28px;
	border-radius: 35px;
	z-index: 1;
	border: 1px solid #f6f6f6;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	animation-name: widget-filtro;
	animation-fill-mode: forwards;
	animation-duration: 500ms;
	animation-delay: 500ms;
}

@keyframes widget-filtro {
	0% {
		opacity: 0;
		transform: translate(-50%, 0);
	}

	100% {
		opacity: 1;
		transform: translate(-50%, -40px);
	}
}

.home .widget-filtro>a {
	display: block;
	margin-top: 10px;
	margin-bottom: 10px;
	padding: 10px 30px;
}

.home .filtro-ciudades:not(:last-child),
.home .filtro-fecha:not(:last-child) {
	border-right: 1px solid lightgrey;
}

.home .filtro-ciudades.active {
	text-align: center;
}

.home .filtro-fecha.active {
	min-width: 175px;
	text-align: center;
}

.home .widget-filtro>a.filtro-pax {
	display: flex;
	align-items: center;
}

.home .filtro-pax span:first-child {
	margin-right: 1em;
	min-width: 90px;
	text-align: right;
	display: block;
}

.home .filtro-pax span:nth-child(2) {
	margin-right: 0.25em;
}

.home .widget-filtro .filtro-precio {
	padding: 0 20px;
}

.home .widget-filtro .filtro-precio>span {
	margin-right: 5px;
}

.home .widget-filtro .filtro-precio>.price-filter-option {
	margin: 0 5px;
	text-transform: uppercase;
	padding: 4px 10px 2px;
	border: 2px solid var(--resaltado-bordes);
	background: white;
	color: var(--gris-texto);
	border-radius: 20px;
	font-weight: 100;
	font-size: 15px;
	display: inline-flex;
	justify-content: flex-start;
	align-items: center;
	width: calc(4.2em + 2px);
	letter-spacing: 2px;
}

.home .widget-filtro .filtro-precio>.price-filter-option.dimmed {
	opacity: 0.5;
}

.home .widget-filtro .filtro-precio>.price-filter-option.active {
	border-color: #333;
	color: white;
	background-color: #333;
}

.home .widget-filtro .kind-filter {
	border-radius: 30px;
	padding: 0.15em;
	margin-left: 30px;
	border: 1px solid #fafafa;
	display: flex;
	margin-right: -25px;
	gap: 10px;
}

.home .widget-filtro .kind-filter a {
	padding: 0.85em 2em;
	border-radius: 30px;
	background: white;
	border: 1px solid #dadada;
}

.home .widget-filtro .kind-filter.has-active a {
	border-color: transparent;
	background: white;
	color: #5c5c5c;
	border: 1px solid #ededed;
}

.home .widget-filtro .kind-filter a:hover {
	background-color: #f6f6f6;
}

.home .widget-filtro .kind-filter a.active {
	background-color: #f6f6f6;
	border: 1px solid #ddd;
	color: black;
}

.home .boton-cantidad .icon-svg-container {
	cursor: pointer !important;
	margin-right: 0 !important;
}

.home .boton-cantidad>div {
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;
	background-color: transparent;
	border-radius: 16px;
}

.home .boton-cantidad>div:hover {
	background-color: #f6f6f6;
}

.home .information {
	display: flex;
	background-color: #fdf6ed;
	margin-bottom: 50px;
	margin-top: 25px;
}

.home.information-first .information {
	margin-top: 40px;
}

.home .information+section.top-list {
	padding-top: 0;
}

.home .top-list+.top-list {
	padding-top: 0;
	margin-top: -60px;
}

.home.subscriptions-enabled .top-list+.top-list {
	margin-top: 0;
}

.home.information-first .top-list+.top-list {
	margin-top: 0;
}

.home .information .columna {
	font-family: Circular;
	letter-spacing: 1;
	font-size: 18px;
	font-weight: 500;
	color: #5c5c5c;
	line-height: 32px;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	min-height: calc(100vh - 83px);
	flex: 1;
}

.home .information .celda {
	flex: 0.5;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	min-height: 500px;
	padding: 40px 35px 35px 35px;
	box-sizing: border-box;
}

.home .celda img {
	margin: 10px;
	box-sizing: border-box;
	min-width: 100%;
}

.home .information .logos {
	display: flex;
	align-items: center;
	margin-top: 30px;
}

.home .home-subtitulo-paso-2 {
	margin-bottom: 30px;
}

.home .information .logos>div {
	padding: 0 15px;
	min-width: 60px;
	min-height: 60px;
	box-sizing: content-box;
	display: flex;
	justify-content: center;
	align-items: center;
}

.home .step.row.step-video {
	padding: 0;
	overflow: hidden;
	width: 100%;
	height: 70vh;
	position: relative;
	margin: 40px 0;
}

.home .top-list+.step.row.step-video {
	margin-top: 75px;
}

.home .step.row.step-video .steps-inner {
	padding: 0;
}

.home .step-video video {
	width: 100%;
	height: auto;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.play-button {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%) scaleY(0.55) translate(35px, 0);
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 50px;
	border-color: transparent;
	border-left-color: white;
	opacity: 0.75;
	cursor: pointer;
	transition: 300ms;
}

.play-button::before {
	display: block;
	content: " ";
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%) scaleY(1.8) translate(-33px, 0);
	width: 120px;
	height: 120px;
	border-radius: 60px;
	background-color: white;
	opacity: 0.25;
	transition: 300ms;
}

.play-button:hover {
	opacity: 0.95;
}

.play-button:hover::before {
	opacity: 0.125;
}

.home .titulo-bodegas {
	font-size: 19px;
	margin-bottom: 10px;
	opacity: 0.5;
	color: black;
}

.widget-filtro .reset-filter {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin-left: 10px;
	width: 24px;
	height: 24px;
	border-radius: 12px;
	background-color: var(--resaltado-transparente);
}

.widget-filtro .reset-filter:hover {
	background-color: #f0f0f0;
}

.widget-filtro>a>div {
	display: inline-block;
}

.home .no-results {
	display: flex;
	justify-content: center;
	align-items: center;
	text-transform: uppercase;
	border-width: 1px 0 1px 0;
	color: var(--gris-texto);
	border-color: #666;
	border-style: solid;
	padding: 50px 0;
	margin: 10px 0 120px;
}

.home.suscripcion .negocio .iconos {
	align-items: center;
	bottom: 5px;
	display: flex;
	justify-content: flex-end;
	position: absolute;
	right: 5px;
}

.home.suscripcion .negocio .iconos .icono {
	height: 32px;
	margin-bottom: 8px;
	margin-right: 8px;
	width: 32px;
	background: white;
	border-radius: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
}

@media (min-width: 1200px) {
	section.steps .step-pago .imagen-ticket {
		position: absolute;
		right: 2%;
		top: -80px;
		width: auto;
		max-height: 700px;
	}

	.welcome-banner .caption-text {
		padding-top: 20%;
	}
}

@media (min-width: 992px) {
	.welcome-banner .caption-text {
		padding-left: 50px;
	}
}

@media (min-width: 576px) and (max-width: 991px) {
	section.steps .step-pago .imagen-ticket {
		right: -15%;
		top: 7%;
		background-size: 35%;
	}
}

@media (max-width: 991px) {
	.welcome-banner .row {
		flex-direction: column-reverse;
	}

	.welcome-banner .steak-image {
		float: none;
		position: relative;
		right: -30%;
		margin-top: 1em;
	}

	.home .fork-knife {
		top: 220px;
		width: 34%;
		position: absolute;
		left: -1%;
	}

	.welcome-banner h1 {
		text-align: left;
		margin-left: 25px;
		font-size: 42px;
		line-height: 1.1;
		margin-top: 15px;
	}

	body .welcome-banner h2 {
		padding: 0 25px;
		font-size: 15px;
		line-height: 24px;
		margin-top: 5px;
		margin-bottom: 25px;
	}

	body .btn {
		font-size: 13px;
		padding: 13px;
	}

	.welcome-banner .caption-text {
		padding-bottom: 1em;
	}

	.top-list {
		padding-top: 100px;
	}

	.home .top-list .col-md-12 {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.home .top-list .review-quote .col-md-12 {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
	}

	.top-list .btn-ver-todos {
		max-width: 40%;
		font-size: 13px;
		margin: 0 0 0 auto;
	}

	.top-list h2 {
		color: var(--gris-texto);
		font-size: 20px;
		line-height: 24px;
		letter-spacing: 1px;
	}

	.review-quote {
		padding: 60px 0 0px;
		text-align: center;
	}

	.review-quote .col-md-12 {
		flex-direction: column;
	}

	.review-quote-text {
		font-size: 26px;
	}

	body .home section.steps .step.row {
		padding-top: 0;
	}

	.steps .col-md-6 {
		justify-content: flex-start;
	}

	.imagen-paso {
		min-height: unset;
	}

	.section-subtitle {
		font-size: 12px;
	}

	section.steps p {
		font-size: 15px;
		line-height: 32px;
		letter-spacing: 1px;
		max-width: unset;
	}

	.steps-inner .row {
		align-items: center;
	}

	.steps .step:first-of-type img,
	.steps .step-regalo img {
		padding-bottom: unset;
	}

	body .steps .full-width .btn.btn-brown {
		max-width: calc(100% - 5px);
		padding: 20px;
		font-size: 14px;
	}

	body .home section.steps .step:nth-child(1).row {
		padding-bottom: 1em;
	}

	.steps .step:nth-child(2) {
		flex-direction: column-reverse;
	}

	.steps .container.full-width:first-of-type {
		padding: 0;
	}

	.bajo-ciudades .section-title {
		font-size: 32px;
		text-align: left;
		font-weight: 500;
		line-height: 40px;
		margin-top: 60px;
	}

	.bottom-banner .caption-text {
		padding: 0 1em;
	}

	.bottom-banner .caption-text h2 {
		font-size: 32px;
		line-height: 40px;
	}

	.bottom-banner .caption-text h3 {
		font-size: 18px;
		line-height: 32px;
		font-weight: 400;
	}

	body .bottom-banner .full-width .btn.btn-brown {
		max-width: 100%;
		padding: 20px;
		font-size: 14px;
	}

	.bottom-banner .container.full-width {
		padding-bottom: 0;
	}

	.home section.steps .step.row:nth-child(1) {
		padding-left: 0;
	}

	.home .steps-inner h2.section-title {
		margin-top: 0;
		font-size: 40px;
	}

	section.steps p,
	section.steps .p {
		margin-bottom: 0;
		font-size: 18px;
		line-height: 32px;
		line-height: 1.7;
	}

	.home section.steps .step.row.step-video {
		height: 28vh;
	}

	.home section.steps .step.row:nth-child(3) {
		padding-right: 0;
	}

	.row.step-3-logos-row:last-child {
		margin-bottom: 30px;
	}

	.home .home-imagen-paso-2 {
		display: none;
	}

	.bodegas-container {
		overflow-x: scroll;
		-ms-overflow-style: none;
		/* Internet Explorer 10+ */
		scrollbar-width: none;
		/* Firefox */
		padding-top: 10px;
	}

	.bodegas-container::-webkit-scrollbar {
		display: none;
		/* Safari and Chrome */
	}

	.carrusel-main-eventos {
		display: flex;
		justify-content: flex-start;
		gap: unset;
	}

	.carrusel-main-evento {
		width: calc(100vw - 25px);
		margin-right: 15px;
	}

	.carrusel-main-evento:first-child {
		margin-left: 15px;
	}

	.carrusel-main-evento:last-child {
		margin-right: 15px;
	}

	.home .banda-otros {
		margin-top: 0;
		padding-top: 0;
		margin-bottom: 0;
		padding-bottom: 0;
	}

	.home .banda-otros>.container {
		flex-direction: column;
	}

	.home .banda-otros .info {
		align-self: unset;
		width: unset;
	}

	.home .banda-otros .container.carousel {
		padding-left: 0;
		margin-top: 20px;
	}

	.home .information {
		overflow-x: scroll;
		overflow-y: hidden;
		-ms-overflow-style: none;
		/* Internet Explorer 10+ */
		scrollbar-width: none;
		/* Firefox */
		max-height: 85vw;
	}

	.home .information::-webkit-scrollbar {
		display: none;
		/* Safari and Chrome */
	}

	.home .information .columna {
		flex-direction: row;
		min-height: unset;
	}

	.home .information .columna:nth-child(2) {
		flex-direction: row-reverse;
	}

	.home .information .celda {
		min-height: unset;
		min-width: 85vw;
		font-size: 17px;
		line-height: 1.3;
		padding-top: 0;
	}

	.home .information .columna:nth-child(1) .celda:nth-child(2),
	.home .information .columna:nth-child(2) .celda:nth-child(1) {
		min-width: 110vw;
	}

	.home .information .columna:nth-child(3) .celda:nth-child(2) {
		min-width: 105vw;
	}

	h2.section-title {
		font-size: 39px;
	}

	.home .information h2.section-title {
		margin-top: 0;
		padding-top: 0;
	}

	.home .information .logos {
		margin-top: 10px;
	}

	.home .banda-otros+.banda-otros {
		margin-top: 0;
	}

	.home .top-list+.banda-otros {
		margin-top: -15px;
	}

	.home .step.row.step-video {
		height: 28vh;
	}
}

@media (max-width: 1600px) {
	section.steps .step-pago .imagen-ticket {
		max-height: 600px;
	}
}

@media (max-width: 1500px) {
	section.steps .step-pago .imagen-ticket {
		max-height: 490px;
	}
}

@media (max-width: 1200px) {
	section.steps .step-pago .imagen-ticket {
		display: none;
	}
}

@media (max-width: 991px) {
	.home .filtro-fecha {
		border-right: none;
	}

	.home .widget-filtro {
		padding: 0;
		height: 70px;
		width: 100%;
		border-radius: 0;
		margin-top: 0;
		transform: none;
	}

	.home section.top-list {
		padding-top: 50px;
	}

	.home .widget-filtro>a {
		text-align: center;
		position: relative;
		padding: 10px 15px;
		margin-left: 10px;
		flex: 1;
	}

	.home .widget-filtro .reset-filter {
		margin: 0 10px;
		position: absolute;
		top: 15%;
		background: #dedede;
		right: 0;
		transform: translate(-50%);
	}

	.home .modal-calendario .pax-selector {
		height: 55px;
		margin: 1em auto;
		flex: 0;
		width: 90%;
	}

	.home .no-results-caption {
		padding: 1em;
		text-align: center;
	}

	.home .calendar-arrow {
		top: 4px;
	}

	.home h2.section-title {
		font-size: 37px;
	}

	.search-results-container .col {
		padding-left: 0;
		padding-right: 0;
	}

	.home .titulo-bodegas {
		margin-left: 15px;
		margin-right: 15px;
	}

	.home .widget-filtro .filtro-precio>.price-filter-option {
		padding: 5px 10px;
		font-size: 14px;
	}

	.home .widget-filtro .filtro-ciudades.active {
		display: flex;
	}

	.home .widget-filtro .filtro-ciudades.active>div:first-child {
		flex: 1;
		margin-right: 25%;
	}

	.home .widget-filtro .kind-filter {
		gap: 10px;
		margin-left: 10px;
		margin-right: 10px;
		flex: 1;
	}

	.kind-floating-menu {
		position: fixed;
		top: 56.25px;
		background-color: white;
		left: 0;
		right: 0;
		text-transform: uppercase;
		display: flex;
		gap: 5px;
		justify-content: space-evenly;
		padding: 5px;
	}

	.kind-floating-menu a {
		flex: 1;
		padding: 1.25rem 0;
		font-weight: bold;
	}

	header .kind-floating-menu a.nav-btn.active {
		background-color: var(--gris-texto);
		color: white;
	}

	body.scrolled:not(.scrolling) .kind-floating-menu {
		bottom: 0;
		top: unset;
		transition: opacity 300ms;
		opacity: 0;
		transform: translateY(10px);
		animation: kind-floating-appear 300ms forwards 150ms;
	}

	body.scrolled.scrolling .kind-floating-menu {
		opacity: 0;
	}

	@keyframes kind-floating-appear {
		0% {
			opacity: 0;
			transform: translateY(10px);
		}

		100% {
			opacity: 1;
			transform: translateY(0px);
		}
	}
}