.para-restaurantes h1 {
    font-size: 60px;
    font-family: Circular, sans-serif;
    line-height: 1.1;
    margin-bottom: 50px;
}

.para-restaurantes .text-block {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    height: 100%;
}

.para-restaurantes .text-block h1 {
    max-width: 610px;
}

.para-restaurantes h2 {
    font-size: 52px;
    font-weight: 500;
    font-family: Circular;
    line-height: 1.2;
    margin-bottom: 32px;
}

.para-restaurantes p {
    font-size: 18px;
    font-family: Circular;
    font-weight: 400;
    line-height: 32px;
    color: #5c5c5c;
}

.para-restaurantes .nuestra-mision {
    margin-top: 75px;
}

.para-restaurantes .nuestra-mision-inner {
    padding: 0;
}

.para-restaurantes .key-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.para-restaurantes .key-icons h3 {
    font-size: 12px;
    font-weight: 500;
    font-family: Circular;
    line-height: 16px;
    line-height: 1.5;
}

.para-restaurantes .nuestro-impacto {
    margin: 32px 0;
}

.para-restaurantes .nuestro-impacto h3 {
    font-size: 16px;
    font-weight: 500;
    font-family: Circular;
    line-height: 16px;
}

.para-restaurantes .key-icons > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20%;
    text-align: center;
    border-right: 1px solid #dee2e6;
    margin: 32px 0;
    padding: 40px 20px;
}

.para-restaurantes .key-icons > div:last-child {
    border-right: none;
}

.para-restaurantes .nuestro-impacto > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20%;
    text-align: center;
    border-left: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    height: 265px;
}

.para-restaurantes .img-wrap {
    height: 175px;
    display: inline-flex;
    align-items: center;
}

.para-restaurantes .tus-clientes {
    margin-bottom: 80px;
    padding-right: 1%;
}

.para-restaurantes .tus-clientes .col-md-6:not(.imagen-paso) {
    padding-left: 0;
}

.para-restaurantes .tus-clientes .imagen-paso {
    padding-right: 50px;
}

.para-restaurantes .tus-clientes p {
    padding-right: 25px;
}

.para-restaurantes .tus-clientes .key-icons {
    justify-content: space-evenly;
    padding-right: 25px;
    width: 100%;
}

.para-restaurantes .tus-clientes .key-icons > div {
    width: 100%;
}

.para-restaurantes .tus-clientes .key-icons > div:first-child {
    /* border-left: 1px solid #dee2e6; */
    border-left: 0;
}

.para-restaurantes .tus-clientes .row {
    align-items: center;
}

.para-restaurantes .tus-clientes h2 {
    font-family: Circular;
    line-height: 62px;
    font-size: 62px;
}

.para-restaurantes .tus-clientes .key-icons h3 {
    font-size: 16px;
}

.para-restaurantes .tus-clientes .key-icons h3 + div,
.para-restaurantes .tus-clientes .nuestro-impacto h3 + div {
    font-size: 30px;
    font-family: Circular;
    line-height: 40px;
    padding-top: 0.8em;
    letter-spacing: 1px;
}

.para-restaurantes .logos {
    padding: 80px 0;
}

.para-restaurantes .logos h2 {
    font-family: Circular;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    width: 100%;
}

.para-restaurantes .logos-inner {
    display: grid;
    grid-template-columns: repeat(6, 6fr);
    gap: 30px;
    max-width: 1180px;
    margin: 0 auto;
}

.caso-de-exito .text-block {
    padding: 100px 0;
}

.caso-de-exito .col-md-6 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.caso-de-exito h2 {
    font-family: Circular;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 1px;
}

.caso-de-exito p {
    font-family: Circular;
    font-size: 32px;
    line-height: 60px;
    letter-spacing: 1px;
    color: var(--gris-texto);
}

.para-restaurantes footer {
    margin-top: 0;
}

.para-restaurantes .container.full-width:not(.bg-offgrey) {
    padding-bottom: 0;
}

@media (max-width: 767px) {
    .para-restaurantes .container.full-width:first-of-type:not(.bg-offgrey) {
        padding-bottom: 40px;
    }

    .para-restaurantes > .container.container.full-width:first-of-type > .row {
        flex-direction: column-reverse;
    }

    .para-restaurantes .text-block h1 {
        margin-left: auto;
        margin-right: auto;
        max-width: 610px;
        font-size: 32px;
        line-height: 40px;
        text-align: center;
        margin-bottom: 1em;
    }

    .para-restaurantes .text-block {
        margin: 0;
        justify-content: flex-start;
        padding-left: 15px;
        padding-right: 15px;
    }

    body .para-restaurantes .text-block .btn.btn-brown {
        margin: 0 0 auto;
        width: 100%;
        max-width: 100%;
        height: 55px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .para-restaurantes .nuestra-mision h2 {
        font-size: 32px;
        line-height: 40px;
    }

    .para-restaurantes .nuestra-mision p {
        font-size: 15px;
        line-height: 32px;
        padding: 1em;
        margin: 0;
    }

    .para-restaurantes .nuestra-mision {
        margin-top: 0;
    }

    .para-restaurantes .key-icons {
        margin-top: 0;
    }

    .para-restaurantes .key-icons > div {
        width: calc(100% - 60px);
        border-color: #dee2e6;
        border-width: 1px 0 0 0;
        padding: 25px;
        border-style: solid;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .para-restaurantes .key-icons > div h3 {
        flex: 1;
    }

    .para-restaurantes .key-icons > div:first-of-type {
        border: none;
        margin-top: 0;
        padding-top: 0;
    }

    .para-restaurantes .key-icons > div:last-of-type {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .para-restaurantes .tus-clientes .imagen-paso {
        padding-right: 0;
    }

    .para-restaurantes .tus-clientes h2 {
        font-size: 44px;
        line-height: 48px;
    }

    .para-restaurantes .tus-clientes .col-md-6:not(.imagen-paso) {
        padding: 40px;
    }

    .para-restaurantes .tus-clientes p {
        font-size: 15px;
        line-height: 32px;
        margin-bottom: 50px;
    }

    .para-restaurantes .tus-clientes .key-icons {
        flex-direction: column;
    }

    .para-restaurantes .tus-clientes .key-icons > div {
        flex-direction: column;
    }

    .para-restaurantes .tus-clientes .key-icons > div:first-child {
        border-left: 0;
    }

    .para-restaurantes .tus-clientes .key-icons > div:last-of-type {
        margin-bottom: 60px;
    }

    .para-restaurantes .tus-clientes + .tus-clientes {
        padding-top: 0;
    }

    .para-restaurantes .nuestro-impacto > div {
        border-left: 0;
        margin: 0;
        padding: 2em;
        height: auto;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .para-restaurantes .nuestro-impacto > div:first-of-type {
        border-top: 1px solid #dee2e6;
    }

    .para-restaurantes .tus-clientes .nuestro-impacto h3 + div {
        padding-top: 0.3em;
    }

    .para-restaurantes .tus-clientes .no-border-bottom {
        border-bottom: 1px solid #dee2e6 !important;
    }

    .para-restaurantes .logos {
        padding: 80px 25px;
    }

    .para-restaurantes .logos-inner {
        grid-template-columns: repeat(3, 3fr);
    }
}
