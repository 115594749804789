.admin-page.admin-estadisticas.informes .admin-bloque.columnas .columna {
    min-height: calc(25vh - 25px);
}

.admin-page.admin-estadisticas.informes .table-component .table-row {
    background-color: white;
}

.admin-page.admin-estadisticas.informes .table-component .row-details {
    margin: -41px 10px 10px;
    background-color: white;
}

.admin-page.admin-estadisticas.informes .row-details>div.row-reservas {
    display: block;
    margin-top: 1.5em;
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
    border-top: 1px solid lightgrey;
    padding-top: 20px;
}

.admin-page.admin-estadisticas.informes .order-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fcfcfc;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    margin-bottom: 10px;
}

.admin-page.admin-estadisticas.informes .order-row>div {
    border-right: 1px solid #efefef;
    font-size: 13px;
    padding: 10px 20px;
}

.admin-page.admin-estadisticas.informes .order-row .date {
    width: 15%;
}

.admin-page.admin-estadisticas.informes .order-row .negocio-name {
    width: 20%;
}

.admin-page.admin-estadisticas.informes .order-row .availability-description {
    width: 20%;
}

.admin-page.admin-estadisticas.informes .download-user-file {
    display: flex;
    align-items: center;
    gap: 1em;
}

body.scrolled .admin-page.admin-estadisticas.informes .header {
    left: 0;
}

.admin-page.admin-estadisticas.informes .custom-dropdown {
    margin-left: 1em;
    border: 1px solid #d3d3d3;
}

.admin-page.admin-estadisticas.informes .kpi-container {
    grid-template-columns: repeat(3, 3fr);
}

@media(max-width: 992px) {
    .admin-page.admin-estadisticas.informes .header {
        flex-direction: row;
        justify-content: center;
    }

    body.scrolled .admin-page.admin-estadisticas.informes .header {
        height: auto;
    }

    .admin-page.admin-estadisticas.informes .order-row {
        flex-direction: column;
    }

    .admin-page.admin-estadisticas.informes .order-row .date,
    .admin-page.admin-estadisticas.informes .order-row .negocio-name,
    .admin-page.admin-estadisticas.informes .order-row .availability-description,
    .admin-page.admin-estadisticas.informes .download-user-file {
        width: 100%;
    }

    .admin-page.admin-estadisticas.informes .download-user-file span {
        margin-left: auto;
    }

    .admin-page.admin-estadisticas.informes .order-row>div {
        font-size: 11px;
        padding: 5px 10px;
    }

    .admin-page.admin-estadisticas.informes h2 {
        font-size: 15px;
    }

    .admin-page.admin-estadisticas.informes .kpi-container {
        grid-template-columns: 1fr;
    }

    .admin-page.admin-estadisticas.informes .table-component.usuarios .table-row {
        display: grid;
        grid-template-columns: 3fr 3fr 3fr;
        margin-left: 0;
        margin-right: 0;
    }

    .admin-page.admin-page.admin-estadisticas.informes .table-component .table-row>span:first-child {
        grid-column-start: 1;
        grid-column-end: 4;
    }

    .admin-page.admin-page.admin-estadisticas.informes .table-component .expand-button {
        border: none;
        top: 0;
        right: 0;
    }

    .admin-page.admin-page.admin-estadisticas.informes .table-component .expand-button .material-symbols-rounded {
        font-size: 29px;
    }

    .admin-page.admin-estadisticas.informes .table-component .table-row > span {
        overflow: unset;
    }

    .admin-page.admin-estadisticas.informes .table-component .row-details {
        margin-left: 0;
        margin-right: 0;
    }
}