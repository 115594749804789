.loading-indicator {
	position: fixed;
	top: 50%;
	left: 50%;
	width: 100px;
	height: 50px;
	transform: translate(-50%, -50%);
	background: white;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
	border-radius: 15px;
	z-index: 100;
}

.loading-indicator .progress-dots {
	height: 50px;
}

.loading-indicator .progress-dots span {
	background-color: #999;
}

.loading-indicator.contained {
	position: absolute;
	background: transparent;
	width: 100%;
	height: 100%;
	transform: none;
	left: 0;
	top: 0;
	display: inline-flex;
	justify-content: center;
	align-items: center;
}

.loading-indicator.contained .progress-dots {
	margin-top: auto;
	margin-bottom: auto;
}

.loading-indicator.contained.with-background .progress-dots {
	background: white;
	width: 50%;
	border-radius: 15px;
	border: 1px solid lightgrey;
}

.loading-indicator .message {
	width: 100vw;
	text-align: center;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top: -125%;
	text-transform: uppercase;
	color: #666;
	font-size: 24px;
	font-weight: bold;
	animation: fadein 1000ms forwards;
}
