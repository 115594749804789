.slider {
    position: relative;
    height: 55vh;
    min-height: 520px;
    width: 100%;
    overflow-x: hidden;
    background-color: black;
    transition: 500ms;
}

.slider .slides {
    position: relative;
}

.slider h1 {
    font-size: 50px;
}

.slider .slides .slide {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.slider .slides .slide .slide-inner {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
}


.slider .slides .slide .slide-inner > div {
    max-width: 40vw;
}

.slider .slide h1,
.slider .slide h2 {
    transition-duration: 500ms;
    transform: translateY(10px);
    opacity: 0;
}

.slider .slide h1 {
    text-transform: uppercase;
}

.slider .slide h2 {
    margin: 1em 0 4em;
    max-width: 800px;
    font-weight: 400;
    font-size: 22px;
}

.slider .slide .call-to-action {
    transition-duration: 500ms;
    transform: translateX(10px);
    opacity: 0;
}

.slider .slide.active h1,
.slider .slide.active h2 {
    transform: translateY(0);
    opacity: 1;
}

.slider .slide.active .call-to-action {
    transform: translateX(0);
    opacity: 1;
}

.slider .thumbnails {
    position: absolute;
    bottom: 15px;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    display: none;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 8px 5px;
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.5);
}

.slider .thumbnail {
    width: 48px;
    height: 48px;
    border-radius: 8px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 4px;
    transform: scale(0.95);
    cursor: pointer;
    transition: 500ms;
}

.slider .thumbnail.active {
    transform: scale(1.1);
}

.slider .btn:not(.btn-brown):not(.btn-small).btn-slider {
    background-color: #fff;
    border: none;
    height: 48px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.slider .slide-background-color {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #00000036;
    display: flex;
    align-items: center;
    padding-bottom: 60px;
}

@media(max-width: 1330px) {
    .slider .slides .slide .slide-inner {
        margin-left: 25px;
        margin-right: 25px;
    }
}

@media(max-width: 768px) {
    .slider .btn-slider {
        max-width: 50%;
    }
    
    .slider .slide h1 {
        font-size: 37px;
        margin-top: 1em;
        text-align: left;
    }

    .slider .slide h2 {
        margin-bottom: 3em;
        margin-top: 2em;
        font-size: 18px;
        line-height: 1.3;
        max-width: 80%;
    }

    .slider .slides .slide {
        padding-bottom: 6em;
    }

    .slider .thumbnails {
        bottom: 15px;
    }

    .slider .slides .slide .slide-inner > div {
        max-width: unset;
    }
}