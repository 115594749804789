.widget-horario-modal-container {
    position: fixed;
    background: #00000077;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1020;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadein 300ms;
}

.widget-horario-modal {
    background: white;
    padding: 25px;
    border-radius: 5px;
    box-shadow: 0 0 15px rgb(0 0 0 / 50%);
    animation: slideup 300ms;
    cursor: default;
    position: relative;
}

@keyframes slideup {
    0% { opacity: 0; transform: translateY(100%); }
    100% { opacity: 1; transform: translateY(0); }
}

@keyframes fadein {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.widget-horario-modal .title {
    font-family: Circular;
    font-size: 20px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    border-bottom: 1px solid lightgrey;
    margin-left: -25px;
    margin-right: -25px;
    margin-top: -25px;
    margin-bottom: 25px;
    padding-left: 25px;
    padding-right: 25px;
}

.day-select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1em auto;
    width: min-content;
    user-select: none;
}

.widget-horario-modal .day-select {
    width: unset;
    margin-bottom: 2em;
}

.day-select a {
    padding: 15px;
    border-radius: 16px;
    width: 30px;
    height: 30px;
    display: inline-flex;
    margin: 0 8px;
    justify-content: center;
    align-items: center;
    background-color: var(--resaltado-claro);
    color: var(--gris-texto);
    border: 1px solid var(--resaltado);
    opacity: 0.5;
}

.day-select a.active {
    background-color: var(--resaltado);
    color: white;
    border: 1px solid transparent;
    opacity: 1;
}

.widget-horario-modal .time-entry {
    margin-bottom: 15px;
    margin-right: 35px;
    display: grid;
    grid-template-columns: 2fr 2fr;
    gap: 15px;
}

.widget-horario-modal .actions {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: -25px;
    margin-right: -25px;
    padding-left: 25px;
    padding-right: 25px;
    border-top: 1px solid lightgrey;
    padding-top: 25px;
}

.widget-horario-modal .input-select .combo-mode-button {
    height: 40px;
    border: 1px solid lightgrey;
    border-radius: 3px;
    width: 100%;
    cursor: pointer;
    min-width: 6em;
    display: inline-flex;
    align-items: center;
    padding: 10px 28px 10px;
}

.widget-horario-modal .input-select.combo-mode .material-symbols-rounded {
    top: 10px;
}

.widget-horario-modal .second-entry-toggle {
    position: absolute;
    right: 20px;
    margin-top: -45px;
}

.widget-horario-modal .btn.btn-brown {
    margin-left: 50px;
}

.widget-horario {
	position: relative;
}

.widget-horario.error {
	color: crimson;
}

.widget-horario .edit-button {
    position: absolute;
    top: 10px;
    right: 20px;
}

.widget-horario .composite-component > div {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
}

.widget-horario .composite-component img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.widget-horario .composite-component:not(:last-of-type) {
    border-bottom: 1px solid lightgrey;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.widget-horario-modal .toggles {
    margin-bottom: 1em;
}

.widget-horario-modal .toggles,
.widget-horario-modal .toggles > div {
    display: flex;
    align-items: center;
}

.widget-horario-modal .form-toggle {
    margin-right: 0.5em;
}

.widget-horario-modal .toggles > div {
    margin-right: 1em;
}
