@media (max-width: 992px) {
    .calendar {
        margin: 25px 25px 0;
    }

    .month h4 {
        font-size: 14px;
        margin-bottom: 20px;
        text-transform: uppercase;
    }

    .calendar-arrow {
        top: 13px;
    }

    .dow {
        font-size: 14px;
    }

    .day,
    .dummy-day {
        font-size: 14px;
    }

    body .modal-inner .month {
        height: 320px;
    }

    .calendar-arrow {
        top: 4px;
    }
}
