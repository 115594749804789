.admin .editar-usuario.edit-page .header {
	height: 56px;
}

.admin .editar-usuario .admin-bloque.invisibles {
	padding: 0 0 0 10px;
	margin: 15px -20px 0 -20px;
}

.admin .editar-usuario .user-toggles {
	display: flex;
	align-items: center;
	padding-bottom: 0;
}

.admin .editar-usuario .user-toggles .form-toggle {
	margin-left: 10px;
	margin-right: 35px;
}
