@media (max-width: 991px) {
    body h1 {
        font-family: Circular;
        font-weight: bold;
        font-size: 60px;
        line-height: 1.1;
        text-align: center;
    }
    h2 {
        font-weight: bold;
    }

    body .welcome-banner h2 {
        padding: 0 25px;
        font-size: 17px;
        line-height: 24px;
    }

    body .btn {
        margin: 0 auto;
        width: 100%;
    }

    body .btn.carousel-reservar-button {
        margin: 25px auto -13px auto;
        display: block;
        max-width: calc(100% - 30px);
    }

    .steps .step:first-of-type img,
    .steps .step-regalo img {
        padding-bottom: 2em;
    }

    .step h2.section-title {
        margin-bottom: 0;
        padding-bottom: 0;
        font-size: 44px;
        line-height: 40px;
    }

    body section.steps .step-pago h2 {
        font-size: 32px;
        text-align: center;
        font-weight: 500;
        line-height: 40px;
    }

    body .home section.steps .step.row {
        padding: 30px 0;
    }

    body .home .section-subtitle {
        padding-top: 2em;
        font-size: 13px;
    }

    body .home .steps-inner img {
        margin: 0 auto;
        display: block;
    }

    body section.steps .steps-3-logo {
        width: 100%;
        height: auto;
        border: none;
    }

    body section.steps .steps-3-logo img {
        margin: auto auto;
    }

    body section.steps .steps-inner {
        padding: 0 15px;
    }

    body .section-title {
        padding-bottom: 0;
    }

    body .section-text {
        margin-top: 0;
    }

    body footer .footer-links {
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-start;
        text-align: left;
    }

    body footer .footer-links > * {
        padding-left: 0 !important;
        margin-left: 0 !important;
        border-left: none !important;
        margin-bottom: 1em;
    }

    body footer .logo {
        margin-bottom: 1em;
    }

    body .btn-ver-todos {
        margin-bottom: 60px;
    }

    body .planear-evento h1 {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        font-size: 60px;
        line-height: 1.1;
        letter-spacing: 1px;
        text-align: left;
    }

    body .planear-evento .full-width .btn.btn-brown {
        max-width: 100%;
    }

    body .planear-evento h2 {
        color: var(--gris-texto);
        font-size: 32px;
        line-height: 40px;
        letter-spacing: 1px;
        max-width: 70%;
    }

    body .planear-evento .text-band {
        padding: 50px 0;
    }

    body .planear-evento .text-block {
        padding: 3rem 15px 1em 15px;
    }

    body .planear-evento .eventos-supertitulo {
        font-size: 12px;
        letter-spacing: 2px;
        color: var(--gris-texto);
        font-weight: bold;
    }

    body .planear-evento .text-band p {
        width: unset;
        max-width: 100%;
        color: #5c5c5c;
        margin-top: 1rem;
        margin-bottom: 2rem;
        font-size: 15px;
        line-height: 32px;
        font-weight: 400;
    }

    body .planear-evento .eventos-destacado {
        padding-top: 0;
        padding-bottom: 40px;
    }

    body .planear-evento .container.full-width:not(.eventos-destacado) {
        padding-bottom: 0;
    }

    body .planear-evento .eventos-destacado h2 {
        font-family: Circular, sans-serif;
        margin-bottom: 32px;
        font-weight: 500;
        font-size: 44px;
        line-height: 48px;
        letter-spacing: 1px;
        color: var(--gris-texto);
        margin-top: 6px;
    }

    body .planear-evento .eventos-destacado .text-block {
        padding-top: 2rem;
    }

    body .planear-evento .eventos-destacado .text-block p {
        margin-top: 6px;
        font-size: 15px;
        line-height: 32px;
        letter-spacing: 1px;
    }

    body .planear-evento .eventos-destacado-1 .row {
        flex-direction: column-reverse;
    }

    body .container.full-width.eventos-banda-inferior {
        padding-top: 0;
    }

    body .planear-evento .full-width.eventos-banda-inferior .btn.btn-brown {
        max-width: 90%;
    }

    body .planear-evento .eventos-banda-inferior h2 {
        color: var(--gris-texto);
        font-size: 32px;
        line-height: 40px;
        letter-spacing: 1px;
    }

    body .planear-evento .logos-clientes {
        padding-left: 1em;
        padding-right: 1em;
    }

    body .full-width .btn.btn-brown {
        max-width: 90%;
    }

    .btn.btn-brown {
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .booking-widget.margin-left {
        margin-left: 0;
    }

    body .modal-inner {
        border-radius: 0;
        margin: 0;
        height: 100vh;
    }

    body .modal-inner .calendar-container {
        padding-top: 25px;
    }

    body .modal-inner .calendar {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
    }

    body .modal-inner .month {
        height: 290px;
    }

    body .modal-inner .slot-buttons > div {
        margin-right: 0;
    }

    body .modal-inner .slot-buttons {
        display: grid;
        grid-template-columns: repeat(2, 2fr);
        gap: 5px;
    }

    body .bajo-ciudades .section-text {
        font-size: 15px;
        font-weight: 400;
        max-width: 642px;
        color: #5c5c5ccc;
        margin: 0;
        padding: 25px 25px 50px;
        font-family: Circular;
        line-height: 32px;
        letter-spacing: 1px;
    }
}
