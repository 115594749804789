.admin-page.admin-usuarios .header .btn {
	width: 154px;
}

.admin-page.admin-usuarios .table-component {
	margin: 40px 20px;
}

.admin-page.admin-usuarios .row-details > div {
	display: grid;
	grid-template-columns: 2fr 2fr;
	gap: 15px;
}

.admin-page.admin-usuarios .row-details > div.row-reservas {
	display: block;
	margin-top: 1.5em;
	margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
    border-top: 1px solid lightgrey;
    padding-top: 20px;
}

.admin-page.admin-usuarios .row-details > div.row-reservas h2 {
	margin-bottom: 20px;
}

.admin-page.admin-usuarios .form-input.select.country-container {
    padding-top: 0;
}

.admin-page.admin-usuarios .order-row {
	display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fcfcfc;
    border-radius: 5px;
    cursor: pointer;
	border: 1px solid #d3d3d3;
    border-radius: 5px;
	margin-bottom: 10px;
}

.admin-page.admin-usuarios .download-user-file {
	display: flex;
    align-items: center;
    gap: 1em;
}

.admin-page.admin-usuarios .user-order-row.no-details .order-row {
	cursor: default;
}

.admin-page.admin-usuarios .order-row .expand-button {
	border-radius: 5px;
}

.admin-page.admin-usuarios .user-order-row:not(.no-details) .order-row:hover {
	background-color: #f6f6f6;
    border-color: grey;
}

.admin-page.admin-usuarios .order-row > div {
	border-right: 1px solid #efefef;
    font-size: 13px;
    padding: 10px 20px;
}

.admin-page.admin-usuarios .order-row .date {
	width: 15%
}

.admin-page.admin-usuarios .order-row .negocio-name {
	width: 20%
}

.admin-page.admin-usuarios .order-row .availability-description {
	width: 20%
}

.admin-page.admin-usuarios .order-row .pax {
	width: 15%
}

.admin-page.admin-usuarios .order-row .slot {
	width: 15%
}

.admin-page.admin-usuarios .order-row .amount {
	width: 10%
}

.admin-page.admin-usuarios .order-row .validated {
	width: 10%
}

.admin-page.admin-usuarios .user-order-row.active {
	margin-bottom: 10px;
}

.admin-page.admin-usuarios .user-order-row.active .order-row {
	margin-bottom: 0;
	border-radius: 5px 5px 0 0;
	border-bottom: none;
	border-color: grey;
}

.admin-page.admin-usuarios .user-order-row.active .order-details {
	padding: 0 10px 10px 10px;
	border: 1px solid grey;
	border-width: 0 1px 1px 1px;
	border-radius: 0 0 5px 5px;
}

.admin-page.admin-usuarios .user-order-row.active .order-details > div {
	border-top: 1px solid #dedede;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
}

.admin-page.admin-usuarios .user-order-row.active .order-details .survey {
    display: grid;
    grid-template-columns: repeat(7, 7fr);
    gap: 10px;
    margin-top: 1em;
}

.admin-page.admin-usuarios .user-order-row.active .order-details .survey .category {
    background: #f6f6f6;
    padding: 15px 20px;
}

.admin-page.admin-usuarios .user-order-row.active .order-details .survey .category.mean-rating {
	background-color: var(--gris-texto);
}

.admin-page.admin-usuarios .star-meter .numeric-value {
	font-size: 11px;
}

.admin-page.admin-usuarios .user-order-row.active .order-details .mean-rating .star-meter .numeric-value {
	background-color: white;
	color: var(--gris-texto);
}

.admin-page.admin-usuarios .user-order-row.active .order-details .survey .mean-rating h3,
.admin-page.admin-usuarios .user-order-row.active .order-details .mean-rating .star-meter > span {
	color: white;
}

.admin-page.admin-usuarios .order-row .rating .star-meter {
	display: flex;
}

.admin-page.admin-usuarios .order-row .rating .star-meter > span {
	font-size: 18px;
}

@media(max-width: 1630px) {
    .admin-page.admin-usuarios .user-order-row.active .order-details .survey {
        grid-template-columns: repeat(6, 6fr);
    }
}

@media(max-width: 1500px) {
    .admin-page.admin-usuarios .user-order-row.active .order-details .survey {
        grid-template-columns: repeat(5, 5fr);
    }
}

@media(max-width: 1330px) {
    .admin-page.admin-usuarios .user-order-row.active .order-details .survey {
        grid-template-columns: repeat(4, 4fr);
    }
}

@media(max-width: 830px) {
    .admin-page.admin-usuarios .user-order-row.active .order-details .survey {
        grid-template-columns: 1fr;
    }
}

.admin-page.admin-usuarios .user-order-row.active .order-details .survey h3 {
    font-size: 13px;
    line-height: 13px;
    font-weight: 400;
}

.admin-page.admin-usuarios .user-order-row.active .order-details .star-meter > span {
    font-size: 23px;
}

.admin-page.admin-usuarios .user-order-row.active .order-details > div:first-child {
	margin-top: 0;
}

.admin-page.admin-usuarios .user-order-row.active .order-details .order-actions {
	display: flex;
    justify-content: flex-end;
}

.admin-page.admin-usuarios .user-order-row.active .order-actions button {
	margin-left: 15px;
	font-size: 0.9em;
    padding: 1em 1.5em;
	margin-top: 0.5em;
	margin-bottom: 0.5em;
}

.admin-page.admin-usuarios .user-order-row.active .order-details .comments > span {
	font-weight: bold;
}

.admin-page.admin-usuarios .user-order-row.active .extras {
	margin-top: 0.5em;
}

.admin-page.admin-usuarios .user-order-row.active .extra-item {
	align-items: center;
    display: inline-flex;
    margin-right: 1em;
}

.admin-page.admin-usuarios .user-order-row.active .extra-item span {
	align-items: center;
    background-color: var(--resaltado);
    border-radius: 3px;
    color: #fff;
    display: inline-flex;
    font-size: .9em;
    height: 1.5em;
    justify-content: center;
    margin-right: 0.5em;
    width: 1.5em;
}

.admin-page.admin-usuarios .row-details > .row-guardar {
	display: flex;
	justify-content: flex-start;
	font-weight: bold;
	border-top: 1px solid lightgrey;
	margin: 20px -20px -20px -20px;
	padding: 20px;
}

.admin-page.admin-usuarios .row-details > div:last-child {
	border-top: 1px solid lightgrey;
	margin: 20px -20px -20px -20px;
	padding: 20px;
}

.admin-page.admin-usuarios .row-details > .row-guardar > div {
	display: flex;
	align-items: center;
}

.admin-page.admin-usuarios .row-details > .row-guardar > div .form-toggle {
	margin-left: 1em;
}

.admin-page.admin-usuarios .row-details .custom-field,
.admin-page.admin-usuarios .row-details .custom-field,
.admin-page.admin-usuarios .row-details .custom-field,
.admin-page.admin-usuarios .row-details .custom-field {
	margin-top: 0;
}

.admin-page.admin-usuarios .row-details .custom-field input[type=email],
.admin-page.admin-usuarios .row-details .custom-field input[type=text],
.admin-page.admin-usuarios .row-details .custom-field input[type=number],
.admin-page.admin-usuarios .row-details .custom-field input[type=password] {
	margin-bottom: 0;
}

.admin-page.admin-usuarios .row-details .boton-guardar {
	margin-left: auto;
}

.admin-page.admin-usuarios .row-details > div.row-estadisticas {
	display: block;
}

.admin-page.admin-usuarios .row-details > div.row-estadisticas .columna {
	min-height: 420px;
}

.admin-page.admin-usuarios .chart-container {
	height: 340px;
	align-items: center;
	width: 50%;
}

.admin-page.admin-usuarios .chart-container > div {
	height: 100%;
}

.admin-page.admin-usuarios .subscription-name {
	flex: 1;
	display: flex;
    align-items: center;
}

.admin-page.admin-usuarios .subscription-name > span {
	margin-right: 0.5em;
}

.admin-page.admin-usuarios .pack-tag {
	text-transform: lowercase;
    background: var(--resaltado);
    color: white;
    border-radius: 9px;
    padding: 0px 5px 1px;
    font-size: 11px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.5em;
}
