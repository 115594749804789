.image-upload-list {
	display: flex;
}

.image-upload-list .image-upload-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.image-upload-list img {
	max-width: 100%;
	height: auto;
}
