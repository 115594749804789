.reserva-completada {
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 25vh;
}

.reserva-completada h1 {
    font-size: 36px;
    line-height: 46px;
}

.reserva-completada h2 {
    font-size: 18px;
    color: #666;
}

.reserva-completada .user-email {
    color: black;
}

.reserva-completada .buttons {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: 1em auto 0;
}

.reserva-completada .add-favorite {
    background-color: #fbf6f2;
    border-radius: 25px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 300ms;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 500;
    margin-left: auto;
    margin-right: auto;
    padding: 0 1em 0 0.7em;
    margin-bottom: 2em;
    margin-top: 0.5em;
    user-select: none;
}

.reserva-completada .add-favorite:hover {
    background-color: #fbf6f277;
}

.reserva-completada .add-favorite .heart-container {
    display: flex;
    align-items: center;
    justify-items: center;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background: white;
    color: black;
    margin-right: 0.5em;
}

.reserva-completada .add-favorite .heart-container i {
    flex: 1;
    font-size: 18px;
}

.reserva-completada .btn-brown {
    padding: 1em 3em;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-top: 1em;
}

@media(max-width: 991px) {
    .reserva-completada h1 {
        font-size: 32px;
        line-height: 38px;
    }
    
    .reserva-completada h2 {
        margin-top: 2em;
        font-size: 15px;
        line-height: 1.5;
    }
}