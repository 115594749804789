.listado-promos {
    display: flex;
    flex-direction: column;
}

.listado-promos .promos-row {
    display: flex;
    padding: 0.5em 1em;
    border-bottom: 1px solid lightgrey;
}

.listado-promos .promos-row:last-child {
    border-bottom: none;
}

.listado-promos .promo-image {
    width: 80px;
    min-width: 80px;
    height: 60px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    margin: 1em 0 1em 0;
}

.listado-promos .promo-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: Circular, sans-serif;
    margin-left: 2em;
    margin-right: 2em;
    flex-shrink: 2;
}

.listado-promos .promo-title {
    font-size: 20px;
}

.listado-promos .promo-descripcion {
    color: #4f4f65;
}

.listado-promos .promo-button {
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.listado-promos .btn.btn-brown {
    font-size: 13px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 2em;
}

@media (max-width: 991px) {
    .listado-promos .promo-button {
        display: none;
    }

    .listado-promos .promo-info {
        margin-right: 0;
    }

    .listado-promos .promo-title {
        font-size: 17px;
    }

    .listado-promos .promo-descripcion {
        font-size: 13px;
    }
}
