.ciudades-carousel.carousel .item-container {
    padding-bottom: 0;
}

.ciudades-carousel .carousel-image-container {
    border-radius: 5px;
}

.ciudades-carousel .item-container:not(.active) .carousel-image-container {
    opacity: 0.5;
    filter: grayscale(1);
}

.ciudades-carousel .carousel-highlight {
    position: absolute;
    text-transform: uppercase;
    left: 10px;
    right: unset;
    top: 10px;
    font-family: "Circular";
    font-size: 13px;
    padding: 5px 15px;
    width: auto;
    height: auto;
    border-radius: 3px;
    background: var(--resaltado);
}
