.admin-bar .save-button {
	font-weight: 700;
    font-family: "Circular";
    background-color: var(--gris-texto);
	color: white;
    font-size: 0.8rem;
    text-transform: uppercase;
    padding: 1.25rem 1.5rem;
	margin: 1rem 7px;
	display: inline-block;
	text-align: center;
    vertical-align: middle;
    line-height: 1.5;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	border: 1px solid black;
	width: 192px;
}

.admin-bar .save-button.disabled {
	opacity: 0.8;
}

.admin-bar .save-button:hover {
	background-color: rgba(255, 255, 255, 0.02);
	color: var(--gris-texto);
}

.admin-bar .bar-elements {
	height: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}
