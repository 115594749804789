.admin-pagina-estatica .image-upload-list .image-upload-container {
	width: 64px;
	height: 64px;
}

.admin-pagina-estatica .image-upload-list {
	justify-content: center;
}

.admin-pagina-estatica .image-upload-container {
	padding: 5px;
	margin: 0 25px;
}

.admin-pagina-estatica .image-upload-list.inferior {
	display: grid;
	grid-template-columns: repeat(4, 4fr);
	gap: 24px;
}

.admin-pagina-estatica .image-upload-list.inferior .image-upload-container {
	width: unset;
	height: unset;
}

.admin-pagina-estatica .admin-bloque:first-child {
	display: flex;
	align-items: flex-end;
}

@media(max-width: 992px) {
	.admin-pagina-estatica .image-upload-list.inferior {
		display: flex;
		flex-direction: column;
	}

	.admin-pagina-estatica .image-upload-list {
		display: grid;
    	grid-template-columns: 2fr 2fr;
	}
}
