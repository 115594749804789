.subscription-types-bar {
    max-width: min(1200px, calc(100vw - 48px));
    margin: 0 auto;
    padding: 35px 0 0;
    position: relative;
    overflow-x: hidden;
    box-sizing: content-box;
}

body.scrolled.subscriptions-bar-visible header:not(.simple) {
    box-shadow: none;
}

.subscription-types-bar .subscription-types-container {
    display: flex;
    align-items: center;
    left: 0;
    transition: left 300ms;
}

.subscription-types-bar::before {
    content: "";
    display: block;
    background: linear-gradient(90deg, white 25%, transparent);
    width: 75px;
    height: calc(100% - 2px);
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0;
    transition: opacity 300ms;
}

.subscription-types-bar:hover::before,
.right-end .subscription-types-bar::before {
    opacity: 1;
}

.subscription-types-bar-container.left-end .subscription-types-bar::before {
    opacity: 0;
    pointer-events: none;
}

.subscription-types-bar-container:not(.right-end) .subscription-types-bar::after {
    content: "";
    display: block;
    background: linear-gradient(270deg, white 25%, transparent);
    width: 75px;
    height: calc(100% - 2px);
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    opacity: 1;
    transition: opacity 300ms;
}

.subscription-types-bar-container.right-end .subscription-types-bar::after {
    opacity: 0;
    pointer-events: none;
}

.subscription-types-bar .move-left,
.subscription-types-bar .move-right {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    opacity: 0;
    transition: 300ms;
    font-size: 32px;
    transition: top 300ms, opacity 300ms;
}

.subscription-types-bar:hover .move-left,
.subscription-types-bar:hover .move-right {
    opacity: 1;
}

.subscription-types-bar .move-right {    
    left: unset;
    right: 0;
}

.subscription-types-bar .move-left {
    transform: translateY(-50%) rotate(180deg);
}

.subscription-types-bar-container.left-end .move-left {
    opacity: 0;
    pointer-events: none;
    left: -24px;
}

.subscription-types-bar-container.right-end .move-right {
    opacity: 0;
    pointer-events: none;
    right: -24px;
}

.subscription-types-bar .subscription-type {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 13px;
    font-weight: 600;
    color: var(--gris-texto);
    padding: 0 8px 11px;
    cursor: pointer;
    user-select: none;
    height: 115px;
    position: relative;
    transition: opacity 300ms;
}

.subscription-types-bar .subscription-type .image {
    transition: opacity 300ms;
    opacity: 0.65;
}

.subscription-types-bar .subscription-type:hover .image,
.subscription-types-bar .subscription-type.active .image {
    opacity: 1;
}

.mis-suscripciones .subscription-types-bar .subscription-type.subscribed.active::after,
.mis-suscripciones .subscription-types-bar .subscription-type.subscribed:hover::after,
.home-suscripcion .subscription-types-bar .subscription-type.active::after,
.home-suscripcion .subscription-types-bar .subscription-type:hover::after {
    content: " ";
    position: absolute;
    bottom: 0;
    left: 0.65em;
    right: 0.65em;
    height: 2px;
    background-color: var(--gris-texto);
    transition: opacity 300ms;
    opacity: 0.65;
}

.subscription-types-bar .subscription-type.active::after {
    opacity: 1;
}

.subscription-types-bar .subscription-type .name {
    margin-top: 0.75em;
    text-align: center;
    opacity: 0.65;
    transition: opacity 300ms;
    white-space: nowrap;
}

.subscription-types-bar .subscription-type .image {
    opacity: 0.65;
}

.mis-suscripciones .subscription-types-bar .subscription-type.subscribed .name,
.mis-suscripciones .subscription-types-bar .subscription-type.subscribed .image {
    opacity: 1;
}

.subscription-types-bar .subscription-type.active .name,
.subscription-types-bar .subscription-type:hover .name,
.subscription-types-bar .subscription-type:hover .image,
.subscription-types-bar .subscription-type.active .image {
    opacity: 1;
}

.subscription-types-bar .subscription-type .image {
    width: 200px;
    height: 75px;
    border-radius: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid lightgrey;
}

.subscription-types-bar-container {
    transition: 300ms;
    border-bottom: 1px solid transparent;
}

.subscription-types-bar-container.floating {
    z-index: 1024;
    position: fixed;
    left: 0;
    right: 0;
    top: 82px;
    background: white;
    border-bottom: 1px solid lightgrey;
}

.subscription-types-bar .pack-tag {
    position: absolute;
    top: 8px;
    right: 18px;
    background: var(--resaltado);
    color: white;
    font-size: 10px;
    text-transform: uppercase;
    border-radius: 15px;
    padding: 2px 10px;
    opacity: 0.45;
    transition: 300ms;
}

.subscription-types-bar .subscription-type:hover .pack-tag,
.subscription-types-bar .subscription-type.active .pack-tag {
    opacity: 1;
}

@media(max-width: 1250px) {
    .subscription-types-bar .subscription-types-container {
        padding-left: 0;
        padding-right: 0;
    }
}

@media(max-width: 992px) {
    .subscription-types-bar .move-left,
    .subscription-types-bar .move-right {
        display: none;
    }

    .subscription-types-bar {
        overflow-x: auto;
        scrollbar-color: transparent;
        margin: 0;
        max-width: unset;
        padding-top: 18px;
        background-color: white;
        border-bottom: 1px solid lightgrey;
    }

    .home .subscription-types-bar.with-filter,
    .mis-suscripciones .subscription-types-bar {
        padding-top: 76px;
    }

    .subscription-types-bar::-webkit-scrollbar {
        display: none;
    }

    .subscriptions-enabled .home .subscription-description.has-button > div {
        max-width: calc(100% - 48px);
    }

    .subscriptions-enabled .home.mis-suscripciones .subscription-description > div {
        padding-bottom: 0;
    }

    .subscriptions-enabled .home .subscription-description .cta {
        float: none;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        margin: 0;
        max-width: 100%;
        width: 100%;
        padding: 1em;
    }

    .subscriptions-enabled .subscription-info-boxes {
        grid-template-columns: 1fr;
        margin-top: 50px;
    }

    .subscriptions-enabled .subscription-info-boxes > div {
        padding: 25px;
    }

    .subscription-types-bar-container:not(.right-end) .subscription-types-bar::after {
        display: none;
    }
}
