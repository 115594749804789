.preorder-ticket {
	border-top: 1px solid lightgrey;
	margin-left: -15px;
	margin-right: -15px;
	margin-top: 15px;
	padding: 15px 15px 0 15px;
	font-size: 13px;
}

.preorder-ticket .title {
	font-weight: bold;
	padding-right: 50px;
	display: flex;
	align-items: center;
}

.preorder-ticket .address {
	color: grey;
}

.preorder-ticket .details {
	display: flex;
	align-items: center;
	position: absolute;
	top: 0;
	right: 0;
	margin-right: 25px;
}

.preorder-ticket .item {
	position: relative;
	margin-bottom: 13px;
	border-bottom: 1px solid #f0f0f0;
	padding-bottom: 13px;
}

.preorder-ticket .item .delete-button {
	width: 19px;
	height: 19px;
	background-color: var(--resaltado-transparente);
	cursor: pointer;
	border-radius: 9.5px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	right: 0;
	top: 0;
}

.preorder-ticket .item .delete-button:hover {
	background-color: #f0f0f0;
}

.preorder-ticket .details > div {
	margin-right: 0.5em;
	display: flex;
	align-items: center;
}

.preorder-ticket .item:last-child {
	margin-bottom: 0;
	padding-bottom: 0;
	border-bottom: none;
}

.preorder-ticket .extras {
	margin-top: 5px;
}

.preorder-ticket .extra-row {
	display: flex;
	align-items: center;
	flex: 1;
	position: relative;
}

.preorder-ticket .extra-row > span:first-child {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid var(--resaltado);
	border-radius: 3px;
	width: 16px;
	height: 16px;
	font-size: 10px;
	margin-right: 10px;
}

.preorder-ticket .extra-row > span:last-child {
	position: absolute;
	right: 0;
}

.preorder-ticket .qty {
	width: 20px;
	height: 20px;
	border-radius: 10px;
	font-size: 11px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	background: var(--resaltado);
	color: white;
	text-align: center;
	margin-right: 0.5em;
}

@media (max-width: 992px) {
	.preorder-ticket .qty {
		display: none;
	}
}
