.icon-selector img {
	width: 32px;
	height: 32px;
	cursor: pointer;
}

.icon-selector .icon-grid {
	margin: 25px;
	display: grid;
	gap: 25px;
	grid-template-columns: repeat(10, 10fr);
}

.icon-selector .placeholder {
	cursor: pointer;
	background-color: #f6f6f6;
	border: 1px solid lightgrey;
	border-radius: 3px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;
}

.icon-selector .placeholder span {
	margin-left: 5px;
}
