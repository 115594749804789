.widget-attachment-limits {
	opacity: 0;
	position: absolute;
	padding: 5px 10px;
	font-size: 13px;
	font-weight: bold;
	background-color: #333333dd;
	color: white;
	border-radius: 3px;
	z-index: 1;
	pointer-events: none;
}

.widget-attachment-limits .separator {
	border-left: 1px solid grey;
	height: 1em;
}

.widget-attachment-limits.compact {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}
