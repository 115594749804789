.admin-page.admin-descuentos .custom-field {
    margin-top: 11px;
}

.admin-page.admin-descuentos .custom-field.type-date,
.admin-page.admin-descuentos .custom-field.type-number {
    margin-top: 0;
}

.admin-page.admin-descuentos .calendar-next {
    right: 15px;
}

.admin-page.admin-descuentos .calendar-previous {
    left: 15px;
}

.admin-page.admin-descuentos .custom-field .currency-symbol {
    top: calc(50% - 5px);
    font-size: 13px;
}

.admin-page.admin-descuentos .custom-field .number-field-container .plus img,
.admin-page.admin-descuentos .custom-field .number-field-container .minus img {
    width: 14px;
    height: 14px;
}

.admin-page.admin-descuentos .custom-field .number-field-container .plus,
.admin-page.admin-descuentos .custom-field .number-field-container .minus {
    width: 22px;
    height: 22px;
}

.admin-page.admin-descuentos .custom-field input,
.admin-page.admin-descuentos .custom-field.type-date input,
.admin-page.admin-descuentos .custom-field.type-number input,
.admin-page.admin-descuentos .custom-field.type-currency input {
    border: none;
    background: var(--resaltado-claro);
    font-size: 13px;
}

.admin-page.admin-descuentos select {
    font-size: 13px;
    background: white;
}

.admin-page.admin-descuentos .tipo-descuento {
    border: none;
}

.admin-page.admin-descuentos .tipo-descuento .options-list {
    width: 100px;
}

.admin-page.admin-descuentos .boton-nuevo-codigo {
    float: right;
}

.admin-page.admin-descuentos h2 {
    margin-top: 50px;
}

.admin-page.admin-descuentos .table-component .table-row {
    border: 1px solid lightgrey;
    border-radius: 5px;
    margin: 0 0 10px 0;
	text-align: center;
	font-size: 0.8em;
    display: flex;
    align-items: center;
	color: #333;
	text-transform: uppercase;
	height: 56px;
	user-select: none;
	background-color: #fcfcfc;
}

.admin-page.admin-descuentos .table-component .table-header > span {
    padding: 0;
}

.admin-page.admin-descuentos .custom-field.type-date .date-value {
    width: 110px;
}

.admin-page.admin-descuentos .custom-field.type-date .date-value > span {
    font-size: 21px;
}

.admin-page.admin-descuentos .user-finder {
    font-size: 13px;
}

.admin-page.admin-descuentos .table-component .table-row .user-finder .user-search-results {
    text-transform: none;
    font-size: 13px;
}