.progress-dots {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.progress-dots span {
    background: white;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    margin: 0 3px;
    animation-name: dots-anim;
    animation-duration: 1000ms;
    animation-direction: normal;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.progress-dots .progress-dot-1 {
    animation-delay: 100ms;
}

.progress-dots .progress-dot-2 {
    animation-delay: 200ms;
}

.progress-dots .progress-dot-3 {
    animation-delay: 300ms;
}

.btn.btn-brown.async-button.confirmation,
.btn.btn-brown.async-button.confirmation:hover {
    background: #fbf6f2;
    box-shadow: -600px 0 0 #fbf6f2 inset;
    -webkit-box-shadow: -600px 0 0 #fbf6f2 inset;
    border: 1px solid #333;
    padding: 0;
}

.btn.btn-brown.async-button.confirmation .icon-svg-container,
.btn.btn-brown.async-button.confirmation:hover .icon-svg-container {
    margin-right: 0 !important;
}

@keyframes dots-anim {
    0%,
    50% {
        transform: translateY(0) scale(1);
        opacity: 1;
        margin: 0 3px;
    }

    25% {
        transform: translateY(2px) scale(0.25);
        opacity: 0;
        margin: 0 3px 0 0;
    }
}
