.gracias-encuesta {
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 25vh;
}

.gracias-encuesta h1 {
    font-size: 36px;
    line-height: 46px;
}

.gracias-encuesta h2 {
    font-size: 18px;
    color: #666;
}

.gracias-encuesta .buttons {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: 1em auto 0;
}
.gracias-encuesta .btn-brown {
    padding: 1em 3em;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-top: 1em;
}

@media(max-width: 991px) {
    .gracias-encuesta h1 {
        font-size: 32px;
        line-height: 38px;
    }
    
    .gracias-encuesta h2 {
        margin-top: 2em;
        font-size: 15px;
        line-height: 1.5;
    }
}
