.event-carousel .item-container {
    border-radius: 5px;
    box-shadow: 0 0px 15px rgb(0 0 0 / 10%);
    transition: 500ms;
    border: 1px solid transparent;
    cursor: pointer;
    overflow: hidden;
}

.event-carousel .item-container:hover {
    box-shadow: 0 0px 15px rgb(0 0 0 / 20%);
    border-color: rgba(0, 0, 0, 0.1);
}

.event-carousel .item-container .image-container {
    border-radius: 5px;
    height: 220px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    box-shadow: 0 -51px 20px inset rgb(0 0 0 / 40%);
}

.event-carousel .item-container .icons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 5px;
}

.event-carousel .item-container .details {
    border-radius: 5px;
    padding: 10px 15px 10px 15px;
    font-size: 12px;
    color: white;
    border-radius: 0 0 5px 5px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transition: 300ms;
}

.event-carousel .item-container .city {
    text-transform: uppercase;
    font-size: 13px;
}

.event-carousel .item-container .name {
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
}
