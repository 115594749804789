.carousel .tns-outer {
    position: absolute;
    width: 100%;
}

.carousel-image-container {
    height: 256px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px 5px 0 0;
    position: relative;
}

.carousel-image-container a.image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px 5px 0 0;
}

.carousel .tns-nav {
    max-width: 800px;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
    margin: 30px auto;
    display: none;
}

.carousel .tns-nav button {
    border: none;
    height: 2px;
    background: #97979736;
    flex: 1;
}

.carousel .tns-nav .tns-nav-active {
    background: var(--gris-texto);
}

.carousel .tns-inner#tns1-iw {
    margin-bottom: 25px;
}

.carousel .tns-item {
    background: transparent;
    border-radius: 5px;
}

.carousel .item-container {
    box-shadow: 0px 4px 8px 0px rgba(27, 28, 36, 0.08);
    background: white;
    border-radius: 5px;
    opacity: 0;
    animation-name: carousel-item-container;
    animation-duration: 500ms;
    animation-fill-mode: forwards;
    animation-delay: 500ms;
}

@keyframes carousel-item-container {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.carousel .item-container > a {
    display: block;
}

section:not(.bg-offwhite):not(.bg-offgrey).container.carousel {
    background: transparent;
}

.carousel-container {
    position: relative;
    height: 242px;
    padding-top: 10px;
    overflow-x: hidden;
}

.carousel-inner {
    display: flex;
    justify-content: space-between;
    position: absolute;
    transition: left 300ms;
}

.carousel-element {
    flex: 1;
}

.carousel-highlight {
    background: var(--resaltado);
    position: absolute;
    top: 10px;
    right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Circular;
    font-size: 12px;
    font-weight: bold;
    z-index: 1;
    padding: 5px 13px;
    color: white;
    border-radius: 20px;
    text-transform: uppercase;
    text-shadow: 0 0 0 white;
}

.carousel-nav-button {
    transition: 250ms;
    position: absolute;
    top: 0;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    cursor: pointer;
    user-select: none;
    transform: translateY(-100%);
    border-radius: 5px;
    border: 1px solid lightgrey;
}

.carousel-nav-button img {
    width: 8px;
    height: auto;
    filter: opacity(0.65);
}

.carousel-nav-button:hover {
    border: 1px solid grey;
}

.carousel-nav-button:hover img {
    filter: opacity(1);
}

.carousel-nav-button-right {
    right: 15px;
}

.carousel-nav-button-left {
    right: 60px;
}

.carousel-title {
    font-family: Circular;
    font-size: 20px;
    padding: 15px 15px 5px;
    text-transform: uppercase;
    font-weight: 700;
}

.tns-ovh {
  padding: 16px;
  margin: -16px;
}

.container.carousel.hide-nav .carousel-nav-button {
    display: none;
}

@media(max-width: 991px) {
    .carousel-nav-button {
        display: none;
    }

    .carousel-container {
		padding-top: 35px;
    }
    
    .btn.carousel-reservar-button {
        padding: 21px;
        font-size: 15px;
    }

    .carousel-mobile {
        overflow-x: scroll;
        height: auto;
        margin-top: 25px;
        margin-bottom: 25px;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
         scrollbar-width: none;  /* Firefox */
    }

    .carousel-mobile::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }

    .carousel-mobile .horizontal-scroll-container {
        padding-bottom: 10px;
    }

    .carousel-mobile .horizontal-scroll-container > div {
        width: calc(100vw - 61px);
        display: inline-block;
        padding-right: 15px;
    }

    .carousel-mobile .horizontal-scroll-container > div:last-child {
        padding-right: 0;
    }

    .carousel-mobile .restaurant-image {
        filter: none;
    }

    .carousel-mobile h3 {
        font-size: 20px;
        margin-bottom: 10px;
    }
}
