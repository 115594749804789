.admin-page.admin-reservas .date {
	color: var(--gris-texto);
	font-family: Circular;
	font-size: 20px;
	line-height: 32px;
	letter-spacing: 1px;
	font-weight: bold;
	margin-bottom: 15px;
}

.admin-page.admin-reservas .date-group {
	margin-bottom: 50px;
}

.admin-page.admin-reservas .date-group:first-of-type .date {
	margin-bottom: 50px;
}

.admin-page.admin-reservas .event-name {
	opacity: 0.8;
	font-size: 18px;
	color: var(--gris-texto);
	font-family: Circular;
	line-height: 32px;
	letter-spacing: 1px;
	font-weight: bold;
	margin-top: 25px;
	margin-bottom: 15px;
}

.admin-page.admin-reservas .new-order-button {
	position: relative;
    margin-left: calc(100% - 230px);
    right: 0;
    width: 230px;
    top: 0;
}

.admin-page.admin-reservas .orders-list {
	margin-top: 25px;
}

.admin-page.admin-reservas .orders-list .order {
	border: 1px solid lightgrey;
	margin-bottom: 10px;
	border-radius: 5px;
}

.admin-page.admin-reservas .orders-list .order.active {
	border-color: #292929;
}

.admin-page.admin-reservas .orders-list .order-row {
	display: flex;
	align-items: center;
	cursor: pointer;
	border-radius: 5px;
	background-color: #fcfcfc;
}

.admin-page.admin-reservas .orders-list .order-row div {
	user-select: none;
}

.admin-page.admin-reservas .orders-list .order-row:hover {
	border-color: grey;
	background-color: #f6f6f6;
}

.admin-page.admin-reservas .orders-list .order-row > div {
	padding: 10px 20px;
	border-right: 1px solid #efefef;
	font-size: 13px;
}

.admin-page.admin-reservas .orders-list .order-row .expand-button {
	width: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
}

.admin-page.admin-reservas .orders-list .order.dragged {
	opacity: 0.5;
}

.admin-page.admin-reservas .orders-list .order .event-name {
	width: 100%;
}

.admin-page.admin-reservas .orders-list .validated {
	width: 100px;
}

.admin-page.admin-reservas .orders-list .name,
.admin-page.admin-reservas .orders-list .email {
	flex: 1;
}

.admin-page.admin-reservas .order-details {
	border-top: 1px solid rgb(230, 230, 230);
	padding: 15px;
}

.admin-page.admin-reservas .order-details .extras {
	margin-top: 0.5em;
}

.admin-page.admin-reservas .order-details .extra-item {
	display: inline-flex;
	align-items: center;
	margin-right: 1em;
}

.admin-page.admin-reservas .order-details .extra-item span {
	background-color: var(--resaltado);
	color: white;
	display: inline-flex;
	width: 1.5em;
	height: 1.5em;
	justify-content: center;
	align-items: center;
	font-size: 0.9em;
	border-radius: 3px;
	margin-right: 0.5em;
}

.admin-page.admin-reservas .order-details .columnas {
	justify-content: flex-start;
	align-items: flex-start;
}

.admin-page.admin-reservas .order-details > div,
.admin-page.admin-reservas .order-details .columnas > div {
	margin-top: 15px;
	border-top: 1px solid #dedede;
	margin-left: -15px;
	margin-right: -15px;
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 10px;
}

.admin-page.admin-reservas .order-details > div:first-child,
.admin-page.admin-reservas .order-details .columnas > div {
	border-top: none;
	margin-top: 0;
	padding-top: 0;
	margin-right: 50px;
}

.admin-page.admin-reservas .order-details > div > span:not(.material-symbols-rounded),
.admin-page.admin-reservas .order-details .columnas > div > span:not(.material-symbols-rounded) {
	font-weight: 700;
	display: block;
}

.admin-page.admin-reservas .availability-details .custom-field input[type=text] {
	margin-bottom: 0;
}

.admin-page.admin-reservas .availability-details .input-select {
	margin-top: 0.5rem;
}

.admin-page.admin-reservas .order-actions {
	display: flex;
	justify-content: flex-end;
	margin-top: 25px;
}

.admin-page.admin-reservas .order-actions.disabled {
	opacity: 0.5;
	pointer-events: none;
}

.admin-page.admin-reservas .order-actions button {
	margin-left: 15px;
}

.admin-page.admin-reservas .new-order-row-details .order-actions button {
	min-width: 25%;
    display: flex;
    justify-content: space-between;
}

.admin-page.admin-reservas .new-order-row-details .extras-configurator .item > div {
	display: flex;
    align-items: center;
    justify-content: space-between;
}

.admin-page.admin-reservas .new-order-row-details .extras-configurator input {
	border: 1px solid #d7e0e2;
}

.admin-page.admin-reservas .orders-list .order-row > div.drag-handle {
	user-select: none;
	padding: 28px 0 28px 0;
	width: 8px;
	height: 8px;
	cursor: grab;
	border-right: none;
	background-image: url(../../static/icons/drag-handle.svg);
	background-size: 8px;
	background-position: center left 16px;
	background-repeat: no-repeat;
	width: 0;
	transition: width 300ms, padding 300ms;
}

.admin-page.admin-reservas .orders-list .drag-placeholder-order {
    height: 42px;
	width: 100%;
    background: lightgrey;
    border: 1px dashed grey;
    border-radius: 3px;
    opacity: 0.15;
	margin-bottom: 16px;
}

.admin-page.admin-reservas .orders-list .drag-placeholder-order:hover {
	opacity: 0.5;
}

.admin-page.admin-reservas .new-order-row-details {
	border: 1px solid #292929;
	border-top: 1px solid rgb(230, 230, 230);
	border-radius: 0 0 5px 5px;
	padding: 15px;
}

.admin-page.admin-reservas .new-order-row-details .accordion-tab-container,
.admin-page.admin-reservas .new-order-row-details .json-editor .plus-wide {
	margin-left: 0;
	margin-right: 0;
}

.admin-page.admin-reservas .new-order-row-details .input-select .combo-mode-button {
	height: 36px;
	border-color: #d7e0e2;
}

.admin-page.admin-reservas .new-order-row-details .input-select.combo-mode {
	margin-top: 8px;
}

.admin-page.admin-reservas .new-order-row-details .order-details-row {
	display: flex;
}

.admin-page.admin-reservas .new-order-row-details .order-details-row:not(:first-of-type) {
	border-top: 1px solid lightgrey;
	margin: 15px -15px 0 -15px;
	padding: 15px 15px 0 15px;
}

.admin-page.admin-reservas .new-order-row-details .order-details-row input {
	border-radius: 3px;
}

.admin-page.admin-reservas .new-order-row-details .order-details-row > div {
	display: flex;
	flex-direction: column;
	margin-right: 15px;
}

.admin-page.admin-reservas .new-order-row-details .order-details-row > div > div:first-child {
	font-weight: bold;
}

.admin-page.admin-reservas .new-order-row-details .order-details-row:first-of-type > div:nth-last-child(2) {
	margin-left: auto;
}

.admin-page.admin-reservas .new-order-row-details .custom-field .number-field-container .minus {
	margin-left: 1.35em !important;
}

.admin-page.admin-reservas .new-order-row-details .order-details-row:first-of-type > div:nth-last-child(2) > div:first-child {
	text-align: center;
}

.admin-page.admin-reservas .new-order-row-details .custom-field .number-field-container {
	justify-content: center;
}

.admin-page.admin-reservas .new-order-row-details .order-details-row:first-of-type > div:last-child {
	text-align: right;
	margin-right: 30px;
	margin-left: auto;
}

.admin-page.admin-reservas .new-order-row-details .order-details-row:first-of-type > div:last-child > div:last-child {
	height: 48px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
