.admin .admin-page.admin-ciudades h2 {
    margin-right: auto;
}

.admin .admin-page.admin-ciudades .ciudades-grid {
    display: grid;
    grid-template-columns: repeat(4, 4fr);
    gap: 25px;
}

.admin .admin-page.admin-ciudades .ciudades-grid .ciudad {
    display: inline-flex;
    flex-direction: column;
    margin-right: 25px;
    margin-bottom: 25px;
    width: 100%;
    height: 250px;
    cursor: pointer;
    border: 1px solid lightgrey;
    border-radius: 3px;
    padding-top: 0;
    box-sizing: border-box;
}
.admin .admin-page.admin-ciudades .ciudades-grid .ciudad:not(.disabled):hover {
    border: 1px solid #292929;
}

.admin .admin-page.admin-ciudades .ciudades-grid .ciudad .details {
    flex: 1 1;
    padding: 15px;
    font-size: 13px;
}

.admin .admin-page.admin-ciudades .ciudades-grid .ciudad .nombre {
    font-weight: bold;
    font-size: 15px;
}

.admin .admin-page.admin-ciudades .ciudades-grid .ciudad.nueva-ciudad {
    justify-content: center;
    align-items: center;
    background-color: white;
}

.admin .admin-page.admin-ciudades .ciudades-grid .image-container {
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
