.combo-select {
	font-size: 0.8;
	user-select: none;
}

.combo-select .selected-option {
	border: 1px solid #292929;
	padding: 21px;
	cursor: pointer;
}

.combo-select .selected-option:hover,
.combo-select.expanded .selected-option {
	background-color: #292929;
	color: white;
}

.combo-select .options {
	position: fixed;
	z-index: 1;
	background-color: white;
	border: 1px solid lightgrey;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
	overflow-y: auto;
}

.combo-select .option {
	border-bottom: 1px solid lightgrey;
	padding: 10px 20px;
	cursor: pointer;
}

.combo-select .option:hover {
	background-color: #292929;
	color: white;
}

.combo-select .option:last-child {
	border-bottom: none;
	margin-bottom: 0;
}

.combo-select.small {
	font-size: 1rem;
	font-weight: 400;
	position: relative;
}

.combo-select.small .selected-option {
	padding: 5px 10px;
	border: 1px solid #d7e0e2;
	margin-bottom: 10px;
}

.combo-select .selected-option:hover,
.combo-select.expanded .selected-option {
	background-color: white;
	color: black;
}

.combo-select .chevron {
	display: none;
}

.combo-select.small .chevron {
	display: block;
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
}