.cambiar-contrasena h1 {
	color: var(--gris-texto);
	font-weight: 700;
	font-family: Circular;
	font-size: 20px;
	line-height: 32px;
	letter-spacing: 1px;
	text-transform: uppercase;
	margin-top: 75px;
	margin-bottom: 1em;
}

.cambiar-contrasena .subtitle {
	font-size: 18px;
	font-weight: 400;
	line-height: 30px;
	text-align: center;
	letter-spacing: 1px;
	max-width: 610px;
}

.cambiar-contrasena .form-container {
	max-width: 600px;
	margin-left: auto;
	margin-right: auto;
}

.cambiar-contrasena .btn.btn-brown {
	margin: 25px 0 10px;
	width: 100%;
}

.cambiar-contrasena form > div:last-child {
	margin-bottom: 50px;
}

.cambiar-contrasena .error-message {
	padding-top: 1em;
	color: var(--error);
}

.cambiar-contrasena .form-input input {
	border-radius: 0;
	background: white;
}

.cambiar-contrasena a {
	text-decoration: underline;
}

.cambiar-contrasena input:focus + label[for=password],
.cambiar-contrasena input:focus + label[for=password2],
.cambiar-contrasena input.non-empty + label[for=password],
.cambiar-contrasena input.non-empty + label[for=password2] {
    top: 10px;
}

.cambiar-contrasena form {
	display: grid;
	grid-template-columns: 2fr 2fr;
	gap: 10px;
}

@media(max-width: 991px) {
	.cambiar-contrasena .btn.btn-brown + div {
		font-size: 14px;
	}

	.cambiar-contrasena form {
		display: flex;
    	flex-direction: column;
	}
}