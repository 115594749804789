.admin .editar-negocio {
	background: #f6f6f6;
}

.admin .editar-negocio .desplegable-tipos,
.admin .editar-negocio .desplegable-ciudades { 
	height: 36px;
    margin-left: 1em;
    margin-top: -3px;
}

.admin.subscriptions-enabled .evento {
	padding-bottom: 0;
} 

.admin .editar-negocio .editor-descripcion {
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid lightgrey;
}

.admin .editar-negocio .accordion-tab.iconos .json-editor.simple .contents {
	display: flex;
}

.admin .editar-negocio .accordion-tab.iconos .json-editor.simple .contents .list-col:first-child {
	flex: 1;
	margin-right: 45px;
}

.admin .editar-negocio .accordion-tab.iconos .json-editor.simple .contents .list-col:nth-child(2) {
	flex: unset;
	margin-left: auto;
	justify-content: center;
}

.admin .editar-negocio .accordion-tab.iconos .json-editor.simple .contents .list-col:nth-child(2) label {
	display: none;
}

.admin .editar-negocio .accordion-tab.iconos .json-editor.simple .contents .icon-selector {
	justify-content: center;
	align-items: flex-end;
	padding-right: 10px;
	flex: 1;
}

.admin .editar-negocio .accordion-tab.iconos .json-editor.simple .contents .icon-selector img,
.admin .editar-negocio .accordion-tab.iconos .json-editor.simple .contents .icon-selector .placeholder {
	width: 64px;
	height: 64px;
}

.editar-negocio .slider-editor-trigger {
	display: flex;
    justify-content: space-between;
	align-items: center;
	gap: 1em;
    padding: 0.5em 1em;
    border: 1px solid lightgrey;
    border-radius: 5px;
    margin-top: 10px;
    cursor: pointer;
}

.editar-negocio .slider-editor-trigger *:last-child {
	margin-left: auto;
}

.editar-negocio .slider-editor-trigger.active {
	padding-bottom: 10px;
	border-bottom: 0;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.editar-negocio .iconos .json-editor .json-list-edit,
.editar-negocio .json-editor.iconos-detalle .json-list-edit {
    grid-template-columns: 1fr;
}

.admin .editar-negocio .json-editor .custom-field .image-upload {
    min-height: 80px;
    min-width: 80px;
}

.admin .editar-negocio .json-editor.iconos .custom-field .image-upload,
.admin .editar-negocio .json-editor.iconos-detalle .custom-field .image-upload {
    width: 80px;
    height: 80px;
}

.admin .editar-negocio .email {
	flex: 1;
}

.admin .editar-negocio .precio-medio {
	margin-left: 1em;
}

.admin .editar-negocio .accordion-tab.detalles .accordion-tab .contents .list-col:first-child {
	float: right;
	margin-top: 16px;
	margin-left: 32px;
}

.admin .editar-negocio .accordion-tab.detalles .accordion-tab .contents .list-col:first-child label {
	display: none;
}

.admin .editar-negocio .accordion-tab.detalles .accordion-tab .contents .list-col:first-child img,
.admin .editar-negocio .accordion-tab.detalles .accordion-tab .contents .list-col:first-child .icon-selector .placeholder {
	width: 64px;
	height: 64px;
}

.admin .edit-page .header .icon-tooltip {
	text-transform: none;
}

.admin .editar-negocio .usuario-administrador {
	margin-bottom: 0.5em;
}

.admin .editar-negocio .eventos-grid {
	display: grid;
	grid-template-columns: repeat(4, 4fr);
	gap: 25px;
    margin-top: 15px;
}

@media (min-width: 1500px) {
	.admin .editar-negocio .eventos-grid {
		grid-template-columns: repeat(5, 5fr);
	}
}

@media (max-width: 1200px) {
	.admin .editar-negocio .eventos-grid {
		grid-template-columns: repeat(2, 2fr);
	}
}

.admin .editar-negocio .eventos .evento {
	display: inline-flex;
	flex-direction: column;
	margin-right: 25px;
	margin-bottom: 25px;
	width: 100%;
	height: 250px;
	cursor: pointer;
	border: 1px solid lightgrey;
	border-radius: 3px;
	padding-top: 0;
	box-sizing: border-box;
	position: relative;
}

.admin .editar-negocio .eventos .evento .dot-menu {
	position: absolute;
    right: 10px;
	top: 5px;
	z-index: 1;
}

.admin .editar-negocio .eventos .evento.nuevo-evento {
	justify-content: center;
	align-items: center;
	background-color: white;
}

.admin .editar-negocio .eventos .evento.nuevo-evento.disabled {
	border-color: lightgrey;
	cursor: default;
	opacity: 0.5;
}

.admin .editar-negocio .disabled-tag {
	z-index: 1;
	left: 10px;
	top: 5px;
	position: absolute;
	text-transform: uppercase;
	font-size: 11px;
	margin-right: 5px;
	display: inline-flex;
	padding: 2px 7px;
	border-radius: 5px;
	background-color: var(--resaltado);
	color: white;
	justify-content: space-evenly;
	align-items: center;
	font-weight: bold;
	margin-top: 5px;
}

.admin .editar-negocio .eventos .evento:not(.disabled):hover {
	border: 1px solid #292929;
}

.admin .editar-negocio .evento .image-container {
	position: relative;
	width: 100%;
	height: 200px;
	background-position: center;
	background-size: cover;
	border-radius: 3px 3px 0 0;
	margin-bottom: auto;
}

.admin .editar-negocio .evento.disabled .image-container {
    opacity: 0.5;
    filter: grayscale(1);
}

.admin .editar-negocio .evento .details {
	flex: 1;
	padding: 15px;
	font-size: 13px;
}

.admin .editar-negocio .evento .nombre {
	font-weight: bold;
	font-size: 15px;
}

.admin .editar-negocio .evento .subscription-types {
	margin-top: 5px;
}

.admin .editar-negocio .evento .subscription-types span {
	font-size: 0.8em;
    background: transparent;
	border: 1px solid var(--resaltado);
    color: var(--resaltado);
    padding: 0.25em 1em;
    border-radius: 1em;
	margin-right: 0.5em;
	display: inline-block;
	opacity: 0.75;
	margin-top: 5px;
}

.admin .editar-negocio .evento .no-calendar-tag {
	position: absolute;
    bottom: 10px;
    top: unset;
    font-size: 12px;
    padding: 2px 10px;
    right: 12px;
    color: white;
    background: var(--resaltado);
    display: inline-block;
    border-radius: 15px;
    font-weight: 600;
    text-transform: uppercase;
}

.admin .edit-page.editar-negocio .json-editor .plus-wide,
.admin .edit-page.editar-negocio .accordion-tab-container {
	margin-left: 0;
	margin-right: 0;
}

.admin .edit-page.editar-negocio .chart-container {
	height: 100%;
}

.admin .edit-page.editar-negocio .chart-container > div {
	height: calc(100% - 35px);
}

.editar-negocio .estadisticas .columna > div {
    height: 100%;
}

.admin .edit-page.editar-negocio .widget-horario {
	margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
    border-top: 1px solid lightgrey;
    padding-top: 15px;
	margin-top: 20px;
}

.admin .edit-page.editar-negocio .editor-descripcion textarea {
	height: 275px;
}

.admin .edit-page.editar-negocio .extras-container {
    padding-top: 0;
}

.admin .edit-page.editar-negocio .admin-bloque.encuestas .columna {
    display: grid;
    grid-template-columns: repeat(4, 4fr);
    gap: 20px;
}

.admin .edit-page.editar-negocio .admin-bloque.encuestas .columna .category {
	position: relative;
    background: #f6f6f6;
    padding: 15px 20px;
}

.admin .edit-page.editar-negocio .admin-bloque.encuestas .columna .category .star-meter {
	float: left;
}

.admin .edit-page.editar-negocio .admin-bloque.encuestas .columna .category .star-meter span {
	font-size: 2.4em;
}

.admin .edit-page.editar-negocio .admin-bloque.encuestas .columna .category .numeric-value {
	padding: 4px 9px;
    background-color: var(--resaltado);
    color: white;
    font-size: 15px;
    border-radius: 3px;
    font-weight: bold;
    display: inline-block;
    margin-top: 5px;
    margin-left: 10px;
}

.admin .edit-page.editar-negocio .admin-bloque.encuestas .columna .category.mean {
    background-color: var(--gris-texto);
    color: white;
}

.admin .edit-page.editar-negocio .admin-bloque.encuestas .columna .category.mean .star-meter,
.admin .edit-page.editar-negocio .admin-bloque.encuestas .columna .category.mean h3,
.admin .edit-page.editar-negocio .admin-bloque.encuestas .columna .category.mean .star-meter > span.active {
    color: white;
}

.admin .edit-page.editar-negocio .admin-bloque.encuestas .columna .category.mean .numeric-value {
    background: white;
    color: var(--gris-texto);
}

.admin .edit-page.editar-negocio .slider-negocio {
	border-width: 0 1px 1px 1px;
    border-color: lightgrey;
    border-style: solid;
    border-radius: 0 0 5px 5px;
    overflow: hidden;
}

.admin .edit-page.editar-negocio .slider-negocio .no-slides-placeholder {
	display: flex;
	justify-content: center;
}

@media(max-width: 1550px) {
    .admin .edit-page.editar-negocio .admin-bloque.encuestas .columna {
        grid-template-columns: repeat(3, 3fr);
    }

	.admin .edit-page.editar-negocio .admin-bloque.encuestas .columna .category .star-meter span {
		font-size: 3em;
	}

	.admin .edit-page.editar-negocio .admin-bloque.encuestas .columna .category .numeric-value {
		font-size: 16px;
		margin-top: 10px;
	}
}

@media(max-width: 1400px) {
    .admin .edit-page.editar-negocio .admin-bloque.encuestas .columna {
        grid-template-columns: repeat(2, 2fr);
    }
}
