.admin-page.admin-estadisticas .admin-bloque {
    border-top: none;
    padding-bottom: 0;
}

.admin-page.admin-estadisticas .admin-bloque.columnas .columna {
    min-height: calc(50vh - 93px);
}

.admin-page.admin-estadisticas .columna {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-top: 10px;
    min-height: 32vh;
    height: 100%;
}

.admin-page.admin-estadisticas .columna h2 {
    text-align: left;
    align-self: flex-start;
    margin-bottom: 1.5em;
}

.estadisticas .chart-container,
.admin-page.admin-estadisticas .chart-container {
    display: flex;
    margin: 0 auto;
    flex: 1;
    justify-content: center;
}

.estadisticas .bar-chart.chart-container,
.admin-page.admin-estadisticas .bar-chart.chart-container {
    width: 100%;
}

.estadisticas .chart-container > div,
.admin-page.admin-estadisticas .chart-container > div {
    flex: 1;
}

.estadisticas .legend-container,
.admin-page.admin-estadisticas .legend-container {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 75px;
    font-size: 13px;
    user-select: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 25%;
}

.estadisticas .legend-container span,
.admin-page.admin-estadisticas .legend-container span {
    border-radius: 50%;
    margin: 5px 0;
    display: inline-flex;
    justify-content: flex-end;
}

.estadisticas .legend-container li,
.admin-page.admin-estadisticas .legend-container li {
    display: inline-block;
}

.estadisticas .tooltip,
.admin-page.admin-estadisticas .tooltip {
    font-size: 13px;
    background-color: var(--gris-texto);
    color: white;
    font-weight: bold;
    display: flex;
    align-items: center;
    border-radius: 3px;
    padding: 5px 15px;
}

.estadisticas .tooltip span:nth-child(2),
.admin-page.admin-estadisticas .tooltip span:nth-child(2) {
    font-weight: normal;
    margin: 0 0.5em;
}

.estadisticas .country-list,
.admin-page.admin-estadisticas .country-list {
    width: 100%;
}

.estadisticas .country-list .country-count,
.admin-page.admin-estadisticas .country-list .country-count {
    display: block;
    float: right;
    font-size: 13px;
    font-weight: bold;
    text-align: left;
    margin-top: 3px;
    padding-left: 5px;
}

.estadisticas .chart-container.horizontal-bar-chart > div,
.admin-page.admin-estadisticas .chart-container.horizontal-bar-chart > div {
    height: 25px;
    width: 100%;
    position: relative;
}

.kpi-container {
    display: grid;
    grid-template-columns: 2fr 2fr;
    flex: 1;
    width: 100%;
    gap: 20px;
}

.kpi-container .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f6f6f6;
    color: var(--gris-texto);
    font-size: 16px;
    text-align: center;
    position: relative;
    user-select: none;
    min-height: 120px;
}


.kpi-container .caption {
    position: absolute;
    left: 15px;
    top: 15px;
    opacity: 0.75;
    font-size: 15px;
    font-weight: 600;
}

.kpi-container .value {
    font-size: 50px;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
}

.admin-page.admin-estadisticas .admin-bloque.encuestas {
    border-top: 1px solid lightgrey;
    margin-top: 40px;
    min-height: 300px;
}

.admin-page.admin-estadisticas .admin-bloque.encuestas .columna {
    display: grid;
    grid-template-columns: repeat(4, 4fr);
    gap: 20px;
    min-height: unset;
}

.admin-page.admin-estadisticas .admin-bloque.encuestas .columna > h3 {
    grid-column-start: 1;
    grid-column-end: 5;
    display: block;
    text-transform: uppercase;
    margin-left: -20px;
    margin-right: -20px;
    border-bottom: 1px solid lightgrey;
    padding: 0 20px;
    padding-bottom: 10px;
    margin-bottom: 15px;
    font-size: 0.9em;
}

@media(max-width: 1550px) {
    .admin-page.admin-estadisticas .admin-bloque.encuestas .columna > h3 {
        grid-column-end: 4;
    }

    .admin-page.admin-estadisticas .admin-bloque.encuestas .columna {
        grid-template-columns: repeat(3, 3fr);
    }
}

.admin-page.admin-estadisticas .admin-bloque.encuestas .columna .category {
    background: #f6f6f6;
    padding: 15px 20px;
}

.admin-page.admin-estadisticas .header {
    justify-content: flex-start;
}

.admin-page.admin-estadisticas .calendar-filter-toggle {
    font-size: 32px;
}

.admin-page.admin-estadisticas .dates-display {
    margin-left: 1em;
    display: flex;
    align-items: center;
}

.admin-page.admin-estadisticas .reset-dates {
    margin-left: 0.5em;
}

.admin-page.admin-estadisticas .no-data-caption {
    position: absolute;
    top: 50%;
    background-color: #f6f6f6;
    padding: 5px 100px;
    font-size: 13px;
    font-weight: bold;
    color: #5e5e5e;
}

.admin-page.admin-estadisticas .encuestas .no-data-caption {
    width: 100%;
    text-align: center;
}



.admin-page.admin-estadisticas .encuestas .columna {
    display: grid;
    grid-template-columns: repeat(4, 4fr);
    gap: 20px;
}

.admin-page.admin-estadisticas .encuestas .columna .category {
	position: relative;
    background: #f6f6f6;
    padding: 15px 20px;
}

.admin-page.admin-estadisticas .encuestas .columna .category .star-meter {
	float: left;
}

.admin-page.admin-estadisticas .encuestas .columna .category .star-meter span {
	font-size: 2.4em;
}

.admin-page.admin-estadisticas .encuestas .columna .category .numeric-value {
	padding: 4px 9px;
    background-color: var(--resaltado);
    color: white;
    font-size: 15px;
    border-radius: 3px;
    font-weight: bold;
    display: inline-block;
    margin-top: 5px;
    margin-left: 10px;
}

.admin-page.admin-estadisticas .admin-bloque.encuestas .columna .category.mean {
    background-color: var(--gris-texto);
    color: white;
}

.admin-page.admin-estadisticas .admin-bloque.encuestas .columna .category.mean .star-meter,
.admin-page.admin-estadisticas .admin-bloque.encuestas .columna .category.mean h3,
.admin-page.admin-estadisticas .admin-bloque.encuestas .columna .category.mean .star-meter > span.active {
    color: white;
}

.admin-page.admin-estadisticas .admin-bloque.encuestas .columna .category.mean .numeric-value {
    background: white;
    color: var(--gris-texto);
}

@media(max-width: 1550px) {
    .admin-page.admin-estadisticas .encuestas .columna {
        grid-template-columns: repeat(3, 3fr);
    }

	.admin-page.admin-estadisticas .encuestas .columna .category .star-meter span {
		font-size: 3em;
	}

	.admin-page.admin-estadisticas .encuestas .columna .category .numeric-value {
		font-size: 16px;
		margin-top: 10px;
	}
}

@media(max-width: 1400px) {
    .admin-page.admin-estadisticas .encuestas .columna {
        grid-template-columns: repeat(2, 2fr);
    }
}
