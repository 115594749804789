@media(max-width: 992px) {
    .admin-estadisticas .kpi-container {
        display: flex;
        flex-direction: column;
    }

    .estadisticas .legend-container, .admin-page.admin-estadisticas .legend-container {
        margin-left: 0;
    }

    .admin-page.admin-estadisticas .admin-bloque.columnas .columna {
        min-height: calc(25vh);
    }

    .admin-page.admin-estadisticas .chart-container {
        flex-direction: column;
    }

    .admin-page.admin-estadisticas .legend-container span {
        min-width: 20px;
        min-height: 20px;
    }

    .admin-page.admin-estadisticas .chart-container > div {
        text-align: center;
        margin-top: 1em;
        justify-content: center;
    }

    .admin-page.admin-estadisticas .chart-container > div ul {
        display: flex;
    }

    .admin-page.admin-estadisticas .chart-container > div ul p {
        white-space: nowrap;
    }
    
    .admin-page.admin-estadisticas .legend-container {
        width: unset;
    }
}