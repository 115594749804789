.admin-page.admin-subscription-types .subscription-info {
    display: flex;
    border-bottom: 1px solid lightgrey;
    margin-left: -20px;
    margin-right: -20px;
    padding: 0 20px 20px 20px;
    position: relative;
}

.admin-page.admin-subscription-types .type-container {
    position: relative;
}

.admin-page.admin-subscription-types .type-container .delete-type {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
}

.admin-page.admin-subscription-types .subscription-info > div {
    display: flex;
    flex-direction: column;
}

.admin-page.admin-subscription-types .subscription-info > div:nth-child(2) {
    margin-left: 2em;
    flex: 1;
}

.admin-page.admin-subscription-types input,
.admin-page.admin-subscription-types textarea {
    border: 1px solid lightgrey;
    padding: 5px;
    border-radius: 3px;
    margin-bottom: 10px;
}

.admin-page.admin-subscription-types label {
    font-weight: bold;
}

.admin-page.admin-subscription-types .table-row > span > select,
.admin-page.admin-subscription-types .table-row > span > input {
    margin: 0;
    padding: 3px 5px;
}

.admin-page.admin-subscription-types .table-row .custom-field .currency-symbol {
	right: 10%;
	top: calc(50% - 0.35em);
}

.admin-page.admin-subscription-types .table-row .control-group {
	display: flex;
	align-items: center;
}

.admin-page.admin-subscription-types .table-row .icono-grupo {
    height: auto;
    max-height: 60%;
}

.admin-page.admin-subscription-types .sections .columns .section .options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    margin: 0 -20px 20px;
    border-bottom: 1px solid lightgrey;
}

.admin-page.admin-subscription-types .columns {
    display: flex;
    justify-content: space-evenly;
    margin: 0 -20px;
    padding: 0;
    border-top: 1px solid grey;
    border-bottom: 1px solid lightgrey;
}

.admin-page.admin-subscription-types .columns:last-child {
    border-bottom: 0;
    margin-bottom: -20px;
}

.admin-page.admin-subscription-types .columns .section {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-bottom: 20px;
}

.admin-page.admin-subscription-types .columns .section {
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 5px;
    margin-right: 5px;
    border-left: 1px solid lightgrey;
    border-right: 1px solid lightgrey;
}

.admin-page.admin-subscription-types .columns .section:first-child {
    margin-left: 0;
    border-left: none;
}

.admin-page.admin-subscription-types .columns .section:last-child {
    margin-right: 0;
    border-right: none;
}

.admin-page.admin-subscription-types .image-upload.empty {
    min-height: 64px;
}

.admin-page.admin-subscription-types .icons-container .image-upload {
    display: inline-flex;
}

.admin-page.admin-subscription-types .icons-container > div {
    position: relative;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
}

.admin-page.admin-subscription-types .icons-container > div .delete-icon {
    display: none;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    position: absolute;
    right: -12px;
    top: -12px;
    background-color: white;
    border: 1px solid #333;
    z-index: 1;
    font-size: 1.5em;
}

.admin-page.admin-subscription-types .icons-container > div:hover .delete-icon {
    display: flex;
}

.admin-page.admin-subscription-types .icons-container > div:hover .delete-icon:hover {
    color: white;
    background-color: #333;
}

.admin-page.admin-subscription-types .show-cta {
    display: flex;
    padding-top: 1em;
    margin-left: auto;
}

.admin-page.admin-subscription-types .show-cta .form-toggle {
    margin-right: 0.5em;
}

.admin-page.admin-subscription-types .section-faq {
    display: grid;
    grid-template-columns: 2fr 2fr;
}

.admin-page.admin-subscription-types .faq-item {
    display: flex;
    flex-direction: column;
    margin: 5px;
    padding: 10px;
    border: 1px solid lightgrey;
    border-radius: 5px;
    position: relative;
}

.admin-page.admin-subscription-types .faq-item .delete-faq {
    display: none;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    position: absolute;
    right: -6px;
    top: -6px;
    background-color: white;
    border: 1px solid #333;
    z-index: 1;
    font-size: 1.5em;
}

.admin-page.admin-subscription-types .faq-item:hover .delete-faq {
    display: flex;
}

.admin-page.admin-subscription-types .faq-item .delete-faq:hover {
    background-color: #333;
    color: white;
}

.admin-page.admin-subscription-types .faq-item:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
}

.admin-page.admin-subscription-types .faq-item textarea,
.admin-page.admin-subscription-types .faq-item input {
    width: 100%;
}

.admin-page.admin-subscription-types .inner-actions {
    position: absolute;
    top: 0;
    right: 0;
}

.admin-page.admin-subscription-types .new-section {
    display: block;
    margin-top: 20px;
    border-top: 1px solid black;
    border-radius: 0 0 5px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-top-color: black;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: -20px;
}

.admin-page.admin-subscription-types .new-section:hover {
    background-color: #fafafa;
}
