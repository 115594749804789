.reservar-lema {
    font-size: 62px;
    line-height: 62px;
    letter-spacing: 1px;
    text-align: center;
}

.reservar-subtitle {
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    text-align: center;
    letter-spacing: 1px;
    max-width: 610px;
    margin: 25px auto 0 auto;
}

.restaurantes-banner {
    height: 500px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2em;
}

.restaurantes h2 {
    font-size: 20px;
    letter-spacing: 1px;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 40px;
}

.tag-links a::after {
    content: " · ";
}

.tag-links a:last-child::after {
    content: "";
}

.restaurantes h3 {
    color: #5c5c5c;
    font-family: Circular;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 1px;
}

.restaurantes .carousel-container {
    padding-top: 20px;
}

.restaurantes .event-carousel .carousel-image-container {
    height: 150px;
}

.restaurantes .event-carousel .carousel-highlight {
    font-size: 11px;
    padding-right: 11px;
    width: auto;
    height: auto;
}

.restaurantes .loading-indicator {
    position: relative;
    top: unset;
    transform: unset;
}

/* .restaurantes .event-carousel .carousel-highlight {
    position: inherit;
    background: transparent;
    padding: 0;
    text-align: left;
    font-weight: 400;
    color: var(--resaltado);
    text-transform: none;
    top: unset;
    left: unset;
    right: unset;
    display: block;
    padding: 0 0 0 16px;
} */

.restaurantes .event-carousel .carousel-title span:nth-child(2) {
    float: none;
    display: block;
    padding-left: 0;
}

.restaurantes .event-carousel .carousel-info {
    font-size: 10px;
}

.restaurantes .event-carousel .carousel-title {
    padding-bottom: 0;
    text-transform: uppercase;
    font-size: 18px;
}

.restaurantes .carousel-reservar-button {
    font-size: 12px;
    padding: 12px 7px;
    margin-top: 10px;
    height: auto;
}

.restaurantes .carousel-info,
.restaurantes .carousel-title span:nth-child(2) {
    margin-top: 1px;
}

.restaurantes .carousel-container {
    height: 360px;
}

.restaurantes .top-list {
    padding-top: 0;
}

@media (max-width: 1530px) {
    .restaurantes-banner::before {
        left: -13%;
    }
    .restaurantes-banner::after {
        right: 0;
    }
}

@media (max-width: 1380px) {
    .restaurantes-banner::before {
        max-height: 342px;
    }

    .restaurantes-banner::after {
        max-height: 320px;
    }
}

@media (max-width: 1140px) {
    .restaurantes-banner {
        min-height: 400px;
        height: 60vh;
        position: relative;
        justify-content: flex-start;
    }

    .restaurantes h1 {
        margin-top: 40px;
    }

    .restaurantes-banner::before {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        max-width: 65%;
        bottom: -160px;
        background-position-x: 50%;
    }

    .restaurantes-banner::after {
        display: none;
    }
}

@media (max-width: 991px) {
    .restaurantes .carousel-reservar-button.btn.btn-brown {
        height: auto;
    }

    .restaurantes h1 {
        text-align: left;
        font-size: 45px;
        line-height: 1;
        margin-top: 15px;
        text-align: center;
        margin-top: 33px;
    }

    .restaurantes .restaurantes-banner .reservar-subtitle {
        font-size: 15px;
        letter-spacing: 1px;
        max-width: 90%;
        padding: 0 25px;
        line-height: 24px;
        margin-top: 5px;
        margin-bottom: 25px;
    }

    .restaurantes h2 {
        margin-top: 20px;
        text-align: left;
    }

    .restaurantes h3 {
        font-size: 1rem;
        line-height: 1.2;
    }
}
