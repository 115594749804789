.categorized-scroller {
	margin-bottom: 150px;
}

.categorized-scroller .categories h2 {
	display: inline-block;
	padding: 0.5em 1em;
	border-bottom: 2px solid lightgrey;
	flex: 1;
	text-align: center;
	transition: 300ms;
	font-size: 16px;
	cursor: pointer;
	white-space: nowrap;
	margin: 0 auto;
}

.categorized-scroller .categories h2.active {
	border-bottom-color: #333;
}

.categorized-scroller .categories-container {
	overflow-x: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none;
	background: white;
	position: fixed;
	padding-top: 15px;
	left: 0;
	right: 0;
	z-index: 1;
}

.categorized-scroller .categories-container::-webkit-scrollbar {
	display: none;
}

.categorized-scroller .intro-extras {
	padding-top: 75px;
	padding-bottom: 25px;
}

.categorized-scroller .item {
	border: 1px solid #ddd;
	padding: 1em;
	display: flex;
	justify-content: space-evenly;
	color: #666;
	cursor: pointer;
	position: relative;
	margin-bottom: 2px;
}

.categorized-scroller .item:hover {
	border-color: lightgrey;
	border-bottom-width: 3px;
	margin-bottom: 0;
	border-bottom-color: var(--resaltado);
}

.categorized-scroller .item .quantity {
	position: absolute;
	right: 5px;
	top: 5px;
	background: var(--resaltado);
	color: white;
	border-radius: 17px;
	padding: 5px 12px;
	font-weight: bold;
	font-size: 15px;
}

.categorized-scroller .item > div:first-child {
	flex: 1;
}

.categorized-scroller .items h2 {
	margin: 0.5em 0 0;
	font-weight: bold;
}

.preorder .item-quantity-selector {
	margin-top: 1em;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	text-align: center;
}

.preorder .item-quantity-selector .qty-controls {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	margin-left: 1em;
	font-size: 13px;
	flex: 1;
	margin: 0 70px;
}

.preorder .item-quantity-selector .qty-controls .qty {
	background: var(--resaltado);
	color: white;
	border-radius: 5px;
	padding: 0.5em 1em;
	font-weight: bold;
	font-size: 16px;
}

.preorder .item-quantity-selector .qty-button {
	font-weight: 200;
	cursor: pointer;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 25px;
	height: 25px;
	padding: 20px;
	border-radius: 26px;
	background-color: transparent;
}

.preorder .item-quantity-selector .qty-button:hover {
	background-color: #f6f6f6;
}

.preorder .item-quantity-selector .qty-button .tock-icon {
	font-size: 28px;
}

.categorized-scroller .item-image {
	width: 105px;
	height: 105px;
	margin-top: 0;
	margin-bottom: auto;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	margin-left: 1em;
}

.categorized-scroller .otros .item-image {
	height: 114px;
}

.categorized-scroller .item-category {
	margin-bottom: 2em;
}

.categorized-scroller .item-image img {
	visibility: hidden;
	max-width: 175px;
}

.preorder .modal-content-area .item-image {
	min-width: 170px;
	background-size: cover;
	background-position: center;
}

.preorder .modal-content-area .item-image img {
	visibility: hidden;
}

.preorder-selector .boton-confirmar {
	display: block;
	max-width: 350px;
	margin: 5em auto 4em;
	min-width: 300px;
	position: relative;
}

.preorder-selector .boton-confirmar .button-total {
	position: absolute;
	right: 15px;
}

.preorder-selector h3,
.preorder-selector .item-price {
	color: #333;
	font-weight: 600;
	line-height: 1;
}

.preorder-selector h3 {
	font-size: 16px;
}

.preorder-selector .item-price {
	margin-top: 1.5em;
	position: absolute;
	bottom: 15px;
	text-transform: uppercase;
	font-size: 11px;
	margin-right: 5px;
	display: inline-flex;
	padding: 5px 10px;
	border-radius: 5px;
	background-color: var(--resaltado);
	color: white;
	justify-content: space-evenly;
	align-items: center;
	font-weight: bold;
}

.preorder-selector .item-description {
	font-size: 13px;
	line-height: 1.25;
}

.preorder-selector .items-grid {
	display: grid;
	grid-template-columns: 2fr 2fr;
	gap: 1em;
	margin: 0.5em 0;
}

.preorder-selector .button-total {
	display: none;
	margin-left: 1em;
}

.modal-extras.extras-upselling .item-price.muted {
	background-color: lightgrey;
	color: darkslategrey;
	font-weight: normal;
}

.modal-extras.extras-upselling .boton-confirmar-container {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	background: white;
	padding: 25px;
	border-top: 1px solid lightgrey;
}

.modal-extras.extras-upselling .boton-confirmar {
	width: 100%;
}

.modal-extras.extras-upselling .button-total {
	margin-left: 25px;
}
.preorder .modal-content-area {
	padding-bottom: 30px;
}

.preorder .modal-outer {
	z-index: 1021;
}

.preorder .modal-content-area .item-description {
	padding: 30px;
	border-bottom: 1px solid lightgrey;
	margin-bottom: 30px;
}

.preorder .modal-content-area .modal-venue .item-description {
	padding: 0;
	border: none;
	margin: 0 0 0 15px;
}

.preorder .modal-content-area .confirm-item {
	position: relative;
	flex: 2;
	margin: 0 30px 0 0;
	min-width: 300px;
}

.preorder .modal-content-area .confirm-item span {
	position: absolute;
	right: 13px;
	top: 0;
	bottom: 0;
	display: flex;
	align-items: center;
}

.preorder-selector .boton-confirmar-container {
	display: none;
}

.preorder .item-category .category-description {
	font-size: 14px;
	font-weight: 400;
	color: #666;
	padding-bottom: 5px;
}

@media (max-width: 991px) {
	.preorder .modal-content-area {
		max-height: calc(100vh - 60px);
		overflow: scroll;
	}
}

@media (max-width: 766px) {
	.categorized-scroller {
		margin-bottom: 0;
	}

	.preorder-selector .boton-confirmar-container {
		display: block;
	}

	.preorder-selector .boton-confirmar {
		margin-bottom: 0;
	}

	#main > .preorder.container {
		padding-bottom: 100px;
	}

	.preorder .categories h2 {
		line-height: 1.4;
		display: block;
		white-space: nowrap;
	}

	.categorized-scroller .categories-container {
		width: 100vw;
		overflow-x: scroll;
		-ms-overflow-style: none;
		scrollbar-width: none;
	}

	.categorized-scroller .categories-container::-webkit-scrollbar {
		display: none;
	}

	.categorized-scroller .categories {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	.preorder-selector .items-grid {
		display: flex;
		gap: 0;
		flex-direction: column;
		justify-content: flex-start;
		align-items: stretch;
	}

	.categorized-scroller .item {
		margin-bottom: 1em;
	}

	.categorized-scroller .item:hover {
		margin-bottom: calc(1em - 2px);
	}

	.categorized-scroller .item-category {
		margin-bottom: 1em;
	}

	.preorder-selector .boton-confirmar {
		width: 100%;
		max-width: unset;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0;
		border: 1px solid #d3d3d8;
		border-radius: 0.3rem;
		margin-top: auto;
		height: 60px;
		box-sizing: border-box;
		position: absolute;
		bottom: 15px;
		width: calc(100% - 30px);
	}

	.preorder-selector .boton-confirmar-container {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		max-width: unset;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		border-top: 1px solid lightgrey;
		padding: 18px;
		background: white;
		z-index: 1;
		transition: 300ms;
		height: 136px;
		box-sizing: border-box;
	}

	.preorder-selector .boton-confirmar-container.active {
		height: 100%;
		border-top: 1px solid transparent;
	}

	.preorder-selector .order-ticket-toggle {
		margin-bottom: auto;
		user-select: none;
	}

	.preorder-selector .order-ticket-toggle img {
		height: 10px;
		margin-left: 10px;
		margin-bottom: 2px;
		transform: rotate(180deg);
		width: auto;
	}

	.preorder-selector .boton-confirmar-container.active .order-ticket-toggle {
		margin-bottom: 0;
	}

	.preorder-selector .boton-confirmar-container.active .order-ticket-toggle img {
		transform: rotate(0deg);
	}

	.preorder-selector .order-ticket-details {
		align-self: stretch;
		margin-top: 22px;
		overflow: hidden;
		flex-direction: column;
		margin-bottom: auto;
	}

	.preorder-selector .order-ticket-details .change-order-link {
		margin-top: 1em;
	}

	.preorder .order-ticket-details .event-information .image {
		max-width: 100%;
		border-radius: 3px;
		margin: 0 0 15px;
	}

	.preorder-selector .order-ticket-details .details-line {
		display: flex;
		align-items: center;
	}

	.preorder-selector .order-ticket-details .details-line.preorder-selection-item {
		margin-bottom: 5px;
	}

	.preorder-selector .order-ticket-details .details-line .sidebar-quantity {
		margin-right: 0.5em;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: var(--resaltado);
		color: white;
		font-weight: bold;
		width: 1.5em;
		border-radius: 5px;
		height: 1.5em;
		font-size: 0.9em;
	}

	.preorder-selector .order-ticket-details .event-information {
		border-bottom: 1px solid lightgrey;
		padding-bottom: 10px;
		margin-bottom: 10px;
	}

	.preorder-selector .order-ticket-details .booking-information {
		margin-bottom: 70px;
		overflow-y: auto;
	}

	.preorder-selector .btn.disabled.boton-confirmar {
		opacity: 1;
		background-color: #9e9e9e;
	}

	a.btn.boton-confirmar.disabled {
		pointer-events: unset;
	}

	.categorized-scroller .item-image img {
		max-width: 105px;
	}

	.preorder .item-quantity-selector .qty-controls {
		margin: auto 0.5em auto 1em;
		padding: 0;
	}

	.preorder .modal-content-area .confirm-item {
		position: relative;
		flex: unset;
		min-width: 200px;
		width: unset;
		justify-content: flex-start;
		margin: 0 1em 0 0.5em;
		padding-left: 1.25em;
	}

	.preorder .modal-outer.active .modal-inner {
		height: unset;
	}

	.preorder .modal-outer.active .modal-inner.modal-upselling {
		height: 100%;
	}

	.preorder .modal-content-area .modal-venue .item-description {
		margin: 0;
	}
}
