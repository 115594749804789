.user-finder input {
    border: none;
    background: var(--resaltado-claro);
    padding: 5px;
}

.user-finder {
    position: relative;
}

.user-finder .user-search-results {
    position: absolute;
    z-index: 2;
    border-radius: 3px;
    background: white;
    max-height: 350px;
    overflow-y: auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.user-finder .user-search-results .user-item {
    padding: 5px;
    cursor: pointer;
}

.user-finder .user-search-results .user-item:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.user-finder .user-search-results .user-item:last-child {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.user-finder .user-search-results .user-item:hover {
    background-color: #292929;
    color: white;
}