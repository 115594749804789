.pago-suscripcion {
    position: relative;
    max-width: 1000px;
    margin: 0 auto 150px auto;
    padding-bottom: 150px;
}

.pago-suscripcion .main {
    max-width: calc(66% - 3em);
    margin-right: 1em;
    margin-top: 24px;
}

.pago-suscripcion .title {
    color: var(--gris-texto);
    font-family: Circular;
    line-height: 32px;
    letter-spacing: 1px;
    font-weight: bold;
    font-size: 1.75rem;
    margin: 0;
    text-transform: uppercase;
    padding: 15px;
    border-bottom: 1px solid lightgrey;
    border-radius: 3px 3px 0 0;
}

.pago-suscripcion .order-summary {
    border: 1px solid lightgrey;
    border-radius: 3px;
}

.pago-suscripcion .subscription-info {
    padding: 15px;
    border-bottom: 1px solid lightgrey;
}

.pago-suscripcion .subscription-title-line {
    display: flex;
}

.pago-suscripcion .subscription-info .negocio-name {
    margin-top: 10px;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: bold;
}

.pago-suscripcion .subscription-info .event-name {
    margin-top: 10px;
    font-weight: bold;
}

.pago-suscripcion .subscription-title {
    align-items: center;
    background: #fff;
    border: 2px solid var(--resaltado-bordes);
    border-radius: 20px;
    color: var(--gris-texto);
    display: inline-flex;
    font-weight: 100;
    justify-content: space-evenly;
    padding: 0.25em 1em;
    font-size: 13px;
}

.pago-suscripcion .subscription-pack,
.pago-suscripcion .subscription-no-calendar {
    align-items: center;
    background: var(--resaltado);
    border-radius: 20px;
    color: white;
    text-transform: uppercase;
    display: inline-flex;
    justify-content: space-evenly;
    margin: auto 0 auto 1em;
    padding: 0.25em 1em;
    font-size: 10px;
    height: 26px;
    font-weight: 600;
}

.pago-suscripcion .subscription-subtitle {
    line-height: 1.3;
    font-size: 16px;
    color: grey;
}

.pago-suscripcion .total-container {
    border-radius: 0 0 3px 3px;
    display: flex;
    align-items: center;
}

.pago-suscripcion .total {
    flex: 1;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    font-weight: bold;
}

.pago-suscripcion .additional-info {
    padding: 15px 0;
    display: flex;
    align-items: center;
}

.pago-suscripcion .additional-info > div {
    display: flex;
    align-items: center;
}

.pago-suscripcion .additional-info > div > span:first-child {
    margin-right: 5px;
    margin-left: -3px;
}

.pago-suscripcion .additional-info > div:not(:first-child) > span:first-child {
    margin-left: 15px;
}

.pago-suscripcion .stripe-widget-container {
    margin-top: 30px;
}

.pago-suscripcion .stripe-element-container {
    padding: 1em;
    border-radius: 3px;
    border: 1px solid lightgrey;
}

.pago-suscripcion .confirmar {
    height: 58px;
    margin-top: 30px;
    width: 100%;
}

.pago-suscripcion .disclaimer {
    margin-top: 15px;
    color: grey;
}

.pago-suscripcion .slider-container,
.pago-suscripcion .slider-container .slider,
.pago-suscripcion .slider-container .slides,
.pago-suscripcion .slider-container .slide,
.pago-suscripcion .slider-container .slide-background-color,
.pago-suscripcion .slider-container .slide-inner,
.pago-suscripcion .slider-container .slide-inner > div {
    border-radius: 3px;
}

.pago-suscripcion .slider-container h1 {
    color: white !important;
    font-size: 11px;
    top: 10px;
    position: absolute;
    right: 10px;
    margin: 0;
    background: var(--resaltado);
    padding: 5px 10px;
    border-radius: 5px;
    line-height: 1;
}

.pago-suscripcion .sidebar {
    position: absolute;
    right: 0;
    width: 33%;
    top: 0;
}

.pago-suscripcion .discount-box {
    margin-top: 30px;
    padding: 13px;
    border-radius: 3px;
    border: 1px solid lightgrey;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    text-align: center;
}

.pago-suscripcion .discount-box .discount-box-title {
    margin-bottom: 15px;
}

.pago-suscripcion .discount-box input[type="text"] {
    border: 1px solid lightgrey;
    padding: 10px;
}

.pago-suscripcion .discount-box .aplicar-codigo-descuento {
    margin-top: 15px;
    height: 45px;
    padding: 0;
}

.pago-suscripcion .pax-selector {
    display: flex;
    align-items: center;
    padding: 15px;
    border-right: 1px solid lightgrey;
    flex: 1;
}

.pago-suscripcion .pax-selector .pax {
    margin-right: auto;
}

.pago-suscripcion .pax-selector .pax-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 30px;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.pago-suscripcion .pax-selector .pax-button:hover {
    background-color: #f6f6f6;
}

.pago-suscripcion .pax-selector .pax-button span {
    font-size: 32px;
}

.pago-suscripcion .error-message {
    padding: 1em 0;
    text-align: center;
    font-size: 1.1em;
    color: crimson;
}

.pago-suscripcion .time-left {
   padding: 0 15px; 
   text-align: center;
   border-bottom: 1px solid lightgrey;
   animation: slideDown 300ms forwards;
}

@keyframes slideDown {
    0% { margin-top: -15px; padding: 0 15px; opacity: 0 }
    100% { margin-top: 0; padding: 15px; opacity: 1 }
}

.pago-suscripcion .error-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1025;
    background: #ffffffcc;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pago-suscripcion .error-container .error {
    user-select: none;
    background: white;
    border: 1px solid lightgrey;
    border-radius: 3px;
    padding: 20px 30px;
    font-size: 18px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

@media(max-width: 992px) {
    .page-suscripcion {
        padding-bottom: 0;
    }
    
    .pago-suscripcion .main {
        margin-left: 1em;
        max-width: unset;
    }

    .pago-suscripcion .sidebar {
        display: none;
    }

    .pago-suscripcion .total-container {
        flex-direction: column;
    }

    .pago-suscripcion .pax-selector {
        width: 100%;
        border-right: none;
        border-bottom: 1px solid lightgrey;
    }

    .pago-suscripcion .total {
        width: 100%;
    }
}