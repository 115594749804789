.admin-page.admin-pagos {
	background: white;
	height: 100%;
}

.admin-page.admin-pagos .header {
	margin-bottom: 15px;
}

.admin-page.admin-pagos .header select {
	margin-left: 5px;
}

.admin-page.admin-pagos .table-row .tag {
	background: #333;
	color: white;
	padding: 0px 7px 1px;
	border-radius: 5px;
	margin-left: 10px;
	font-size: 0.8em;
}

.admin-page.admin-pagos .percent-input {
	border: 1px solid lightgrey;
	padding: 5px;
	margin-left: 1em;
	margin-right: auto;
	width: 4em;
	position: relative;
	text-align: center;
}

.admin-page.admin-pagos .table-component .totalized-row label {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-bottom: 0;
}

.admin-page.admin-pagos .row-details .row {
	display: flex;
	justify-content: stretch;
	align-items: center;
	padding: 2px;
	margin-bottom: 2px;
	border-bottom: 1px solid #ededed;
}

.admin-page.admin-pagos .row-details .row:last-child {
	border-bottom: none;
}

.admin-page.admin-pagos .renewal,
.admin-page.admin-pagos .no-calendar,
.admin-page.admin-pagos .cancelation {
	display: inline-flex;
	width: 20px;
	min-width: 20px;
	height: 20px;
	background: #c1e1c1;
	border-radius: 10px;
	justify-content: center;
	align-items: center;
	font-size: 0.9em;
	font-weight: 700;
	margin-left: 10px;
	cursor: default;
	user-select: none;
}

.admin-page.admin-pagos .cancelation {
	background: #ff6961;
	color: white;
}
.admin-page.admin-pagos .no-calendar {
	background-color: #61c1ff;
	color: white;
}
