.language-selector {
    position: relative;
    border: 1px solid var(--gris-texto);
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.language-selector .language {
    text-transform: uppercase;
    font-weight: 700;
    font-family: "Circular";
    color: var(--gris-texto);
    font-size: 0.8rem;
}

.language-selector .language:not(.active) {
    display: none;
}

.language-selector:hover .language:not(.active) {
    display: block;
    position: fixed;
    z-index: 1021;
	top: 71px;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 1px solid var(--gris-texto);
    border-top: none;
}

.language-selector:hover .language:not(.active):hover {
    background-color: var(--gris-texto);
    color: white;
}

.mobile-menu .language-selector {
    margin-left: 14px;
    position: absolute;
    bottom: 14px;
}

.mobile-menu .language-selector:hover .language:not(.active) {
    position: absolute;
    border-top: 1px solid var(--gris-texto);
    border-bottom: 0;
    left: -0.5px;
}
