.grid-restaurantes {
    display: grid;
    grid-template-columns: repeat(5, 5fr);
    gap: 20px;
}

.grid-restaurantes .grid-item-container {
    box-shadow: 0px 4px 8px 0px rgb(27 28 36 / 8%);
    background: white;
    position: relative;
    font-family: Circular;
    border-radius: 5px;
    border-bottom: 3px solid transparent;
    transition: border-color 300ms;
    cursor: pointer;
}

.grid-restaurantes .grid-item-container:hover {
    border-color: var(--resaltado);
}

.grid-restaurantes .grid-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 150px;
    border-radius: 5px 5px 0 0;
    position: relative;
}

.grid-restaurantes .grid-image .restaurant-icons-container {
    position: absolute;
    right: 3px;
    bottom: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.grid-restaurantes .grid-highlight {
    background: var(--resaltado);
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Circular;
    font-weight: bold;
    z-index: 1;
    border-radius: 20px;
    padding-bottom: 1px;
    font-size: 11px;
    padding-right: 11px;
    width: auto;
    height: auto;
}

.grid-restaurantes .grid-item-info {
    padding: 15px;
}

.grid-restaurantes .title {
    font-weight: 700;
    padding-bottom: 0;
    text-transform: uppercase;
    font-size: 18px;
}

.grid-restaurantes .city {
    color: var(--resaltado);
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 400;
}

.grid-restaurantes .tags {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 400;
    color: var(--resaltado);
}

.grid-restaurantes a.btn.btn-brown {
    padding: 0;
    font-size: 12px;
    width: 100%;
    border-radius: 5px;
    margin-top: 15px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.grid-restaurantes .no-results-error {
    grid-column: 1 / -1;
    padding: 2em 2em 3em;
    font-size: 1.1em;
    font-weight: bold;
    text-align: center;
}

@media (max-width: 991px) {
    .grid-restaurantes {
        grid-template-columns: 1fr;
        padding-top: 30px;
        padding-left: 30px;
        padding-right: 30px;
        gap: 30px;
    }
}
