.texto-legal .container {
    padding: 100px 0 0 0;
}

.texto-legal .container h1 {
    font-size: 60px;
    line-height: 1.1;
    letter-spacing: 1px;
    color: var(--gris-texto);
    margin-bottom: 0.5rem;
    font-weight: 500;
    max-width: 700px;
}

.texto-legal h2 {
    font-weight: bold;
}

.texto-legal p {
    text-align: justify;
}

@media (max-width: 600px) {
    .texto-legal {
        padding-left: 25px;
        padding-right: 25px;
    }
}
