@media(max-width: 992px) {
    .admin-page.admin-reservas .availability-row-details {
        flex-direction: column;
        padding: 10px 10px 95px 10px;
    }

    .admin-page.admin-reservas .availability-row-details .horarios {
        border-left: 0;
        padding-right: 7px;
        padding-bottom: 7px;
        margin-left: 0;
        padding-left: 7px;
    }

    .admin-page.admin-reservas .availability-row-details .detalles {
        padding: 0 15px 10px 15px;
    }

    .admin-page.admin-reservas .disponibilidad .new-row .availability-actions button {
        margin-left: auto;
        margin-right: auto
    }

    .admin-page.admin-reservas .availability-row-header {
        flex-direction: column;
        height: unset;
    }

    .admin-page.admin-reservas .availability-row-header > div {
        padding: 5px 10px;
        text-align: center;
        border: none;
    }

    .admin-page.admin-reservas .availability-row-header .hora {
        margin-right: unset;
    }

    .admin-page.admin-reservas .availability-row-details .weekday-header {
        margin-top: 3em;
    }

    .admin-page.admin-reservas .availability-row-details .weekday-name {
        top: 20px;
        right: 25px;
    }

    .admin-page.admin-reservas .availability-actions {
        width: 100%;
        right: 0;
        text-align: center;
    }

    .admin-page.admin-reservas .availability-actions button {
        margin-left: 0;
        width: 48%;
        display: inline-block;
        height: 60px;
    }

    .admin-page.admin-reservas .disponibilidad .new-row .availability-actions button {
        width: calc(100% - 32px);
    }
}