.formulario-eventos h1 {
    margin-top: 3rem;
    font-size: 60px;
    line-height: 1.1;
    letter-spacing: 1px;
    color: var(--gris-texto);
    margin-bottom: 0.5rem;
    font-weight: 500;
    text-align: left;
}

.subtitle {
    font-size: 15px;
    letter-spacing: 2px;
    padding: 1rem 0;
}

.formulario-eventos h2 {
    font-size: 24px;
    line-height: 40px;
    letter-spacing: 1px;
    color: var(--gris-texto);
    font-family: Circular;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    border-bottom: 2px solid black;
    padding-bottom: 0.25em;
    margin: 1em 0;
}

.formulario-eventos .form-input {
    margin-bottom: 3rem;
    display: inline-block;
    width: 50%;
}

.formulario-eventos .form-input:nth-of-type(even) {
    margin-left: 30px;
    width: calc(50% - 30px);
}

.formulario-eventos .form-input input,
.formulario-eventos .form-input textarea {
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: #dee2e6;
    border-radius: 0;
    padding: 0.75rem 0;
    width: 100%;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 1px;
}

.formulario-eventos .form-input input:focus,
.formulario-eventos .form-input textarea:focus {
    box-shadow: none;
    outline: none;
}

.formulario-eventos .form-input input + label,
.formulario-eventos .form-input textarea + label {
    cursor: text;
    left: 0;
    color: #b6b6b6;
    font-family: Circular;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 1px;
}

.formulario-eventos .form-input input:focus + label,
.formulario-eventos .form-input input.non-valid:focus + label,
.formulario-eventos .form-input input.non-empty + label,
.formulario-eventos .form-input textarea:focus + label,
.formulario-eventos .form-input textarea.non-valid:focus + label,
.formulario-eventos .form-input textarea.non-empty + label {
    transform: none;
    top: -15px;
    color: #d3d3d8;
    font-size: 18px;
}

.formulario-eventos .form-input .validation-message {
    position: absolute;
}

.formulario-eventos .detalles-container {
    margin-left: 0 !important;
    width: 100% !important;
}

.formulario-eventos .btn {
    padding-left: 4em;
    padding-right: 4em;
}

.formulario-eventos .contacto-titulo {
    margin-top: 3rem;
    font-size: 40px;
    letter-spacing: 1px;
    color: var(--gris-texto);
    margin-bottom: 0.5rem;
    font-weight: bold;
    line-height: 1;
    max-width: 700px;
    text-align: left;
}

.formulario-eventos .contacto-subtitulo {
    font-size: 15px;
    letter-spacing: 2px;
    padding: 1rem 0;
}

@media (max-width: 991px) {
    .formulario-eventos .contacto-titulo {
        font-size: 42px;
    }
    
    .formulario-eventos .form-input input + label,
    .formulario-eventos .form-input textarea + label {
        font-size: 15px;
        line-height: 1;
    }

    .formulario-eventos .form-input {
        width: 100%;
    }

    .formulario-eventos .form-input:nth-of-type(odd) {
        margin-left: 0;
        width: 100%;
    }
}
