.home-suscripcion {
    min-height: 1500px;
    transition: 300ms;
}

.home-suscripcion .top-list {
    display: grid;
    gap: 20px;
    margin: 0 auto 25px;
    max-width: 1200px;
    grid-template-columns: repeat(3, 3fr);
}

.home-suscripcion .negocio {
    animation-delay: .5s;
    animation-duration: .5s;
    animation-fill-mode: forwards;
    animation-name: carousel-item-container;
    border: solid transparent;
    border-radius: 50px;
    border-width: 1px 1px 5px;
    box-shadow: 0 0 15px rgba(0,0,0,.1);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    opacity: 0;
    padding: 10px;
    transition: .5s;
}

.home-suscripcion .negocio .image {
    height: 235px;
    background-size: 355px 267.875px;
    transition: background-size 300ms ease 0s;
    background-position: 50%;
    border-radius: 40px;
    position: relative;
}

.home-suscripcion .negocio .name {
    color: #333;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 15px 15px 0;
}

.home-suscripcion .negocio .city {
    margin-bottom: 10px;
    text-transform: uppercase;
    padding: 0 15px;
    font-size: 13px;
    color: grey;
}

.home-suscripcion .negocio .tag-visitas {
    position: absolute;
    bottom: 16px;
    top: unset;
    font-size: 12px;
    padding: 2px 10px;
    left: 22px;
    color: white;
    background: var(--resaltado);
    display: inline-block;
    border-radius: 5px;
    font-weight: 600;
    text-transform: uppercase;
    cursor: default;
}

.home-suscripcion .tag-out-of-visits {
    background: #ea5555;
    border-radius: 16px;
    color: #fff;
    font-size: 11px;
    padding: 4px 12px;
    position: absolute;
    right: 17px;
    text-transform: uppercase;
    top: 17px;
    z-index: 1;
    cursor: default;
}

.home-suscripcion .iconos-detalle {
    padding: 0 15px;
}

.home-suscripcion .iconos-detalle > img {
    display: inline-block;
    margin-right: 10px;
    filter: grayscale(1);
    height: 32px;
    transition: filter .3s;
    width: 32px;
}

.home-suscripcion .negocio:hover .iconos-detalle > img {
    filter: grayscale(0);
}

.home-suscripcion .negocio .lower {
    margin: 15px auto 0 10px;
    padding: 7px 17px;
    align-items: center;
    background: #fff;
    border: 2px solid #ededed;
    border-radius: 20px;
    color: var(--gris-texto);
    display: inline-flex;
    font-size: 11px;
    font-weight: 100;
    justify-content: space-evenly;
    text-transform: uppercase;
}

.home-suscripcion .negocio .iconos {
    align-items: center;
    bottom: 5px;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 5px;
}

.home-suscripcion .negocio .iconos .icono {
    height: 32px;
    margin-bottom: 8px;
    margin-right: 8px;
    width: 32px;
    background: white;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-suscripcion .person-alert {
    margin: 25px auto 0;
    max-width: min(1200px, calc(100vw - 50px));
    text-align: center;
    padding: 15px;
    border: 1px solid crimson;
    color: crimson;
    border-radius: 3px;
    font-size: 13px;
}

.home-suscripcion .over-pax {
    position: absolute;
    right: 15px;
    top: 15px;
    background-color: #ea5555;
    border-radius: 15px;
    display: flex;
    align-items: center;
    padding: 5px 10px 5px 5px;
    color: white;
    text-transform: uppercase;
    font-size: 12px;
}

.home-suscripcion .over-pax > span {
    margin-right: 5px;
}

.home-suscripcion .top-list .negocio:first-child:last-child {
    flex: 1;
    transition: none;
}

.home-suscripcion .negocios-container {
    overflow-x: auto;
    scrollbar-width: 0;
    min-height: 400px;
    transition: 300ms;
    opacity: 0;
    animation: 300ms fadeinslidedown 1s forwards;
}

.home-suscripcion .negocios-container::-webkit-scrollbar {
    display: none;
}

.home-suscripcion .negocios-container .top-list.responsive {
    display: flex;
}

.home-suscripcion .subscription-types-bar .subscription-type:not(.available) {
    pointer-events: none;
    opacity: 0.5;
}

@media(max-width: 992px) {
    .home-suscripcion .top-list {
        padding: 25px;
        grid-template-columns: 1fr;
    }

    .home-suscripcion .subscription-description.has-button {
        padding-bottom: 86px;
        position: relative;
    }
    
    .subscriptions-enabled .home-suscripcion .subscription-description .cta {
        max-width: calc(100% - 48px);
        margin: 0 24px 24px 24px;
    }
}