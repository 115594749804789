.booking-widget {
    position: sticky;
    top: 100px;
    border: 1px solid #d3d3d8;
    border-radius: 0.3rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: block;
    height: auto;
    padding: 1rem;
}

.booking-widget .btn-brown,
.booking-widget .selectors > div {
    border: 1px solid #d3d3d8;
    border-radius: 0.3rem;
    margin-bottom: 1rem;
    padding: 0.5em 1em 0.5em;
    height: 60px;
}

.booking-widget .btn-brown {
    border-color: var(--gris-texto);
}

.booking-widget .pax-selector .tock-icon {
    font-size: 28px;
    cursor: pointer;
    width: 25px;
    height: 25px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    padding: 20px;
    border-radius: 26px;
}

.booking-widget .pax-selector .tock-icon:not(.disabled):hover {
    background-color: #f6f6f6;
}

.booking-widget .pax-selector .disabled .tock-icon {
    opacity: 0.35;
    cursor: default;
}

.booking-widget .pax-selector {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    font-family: Circular;
    font-size: 16px;
    font-weight: 400;
    user-select: none;
}

.booking-widget .pax-selector .pax {
    margin-right: auto;
    margin-top: 2px;
    font-size: 16.64px;
}

.booking-widget .pax-selector .pax.disabled {
    opacity: 0.5;
}

.pax-button {
    margin-left: 0.5em;
    margin-top: 5px;
    font-weight: 200;
}

.date-selector input,
.slot-selector select {
    border: none;
    width: 100%;
    height: 100%;
}

.booking-widget .btn.btn-brown {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em 0;
    height: 75px;
    margin-bottom: 0;
}

.booking-widget.horizontal {
    padding: 0 2rem 2rem;
    border-radius: 0 0 0.3rem 0.3rem;
    border-width: 0;
    box-sizing: border-box;
    top: 0;
}

.booking-widget.horizontal .selectors {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
    margin-top: 1.5em;
}

.booking-widget.horizontal .selectors > div {
    flex: 1;
    padding-top: 5px;
    padding-bottom: 5px;
    box-sizing: content-box;
}

.booking-widget.horizontal .selectors > div {
    border-radius: 0.3rem;
}

.booking-widget.horizontal .selectors > div:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
}

.booking-widget.horizontal .selectors > div:nth-child(2):not(:last-child) {
    border-radius: 0;
    border-right: none;
}

.booking-widget.horizontal .selectors > div:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    flex-direction: column;
    text-align: left;
}

@media(min-width: 992px) {
    .price-label-subtitle {
        display: none;
    }

    div > .price-label-subtitle {
        font-size: 11px;
        color: grey;
        position: absolute;
        margin-top: -3px;
        right: 63px;
        top: 48px;
        display: unset;
        background: unset;
        white-space: unset;
        padding: unset;
        border-radius: unset;
        box-shadow: unset;
        font-weight: unset;
        transition: unset;
        z-index: unset;
        opacity: unset;
    }

    div.icono-info > .icon-tooltip {
        right: 50px;
        max-width: 300px;
        white-space: normal;
        text-align: left;
        width: auto;
    }

    div.icono-info:hover > .icon-tooltip {
        z-index: 1;
        opacity: 1;
        top: 50px;
    }
}

.slot-buttons {
    transition: 500ms;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
}

.slot-buttons + .slot-buttons {
    margin-top: 1em;
}

.slot-buttons.disabled {
    opacity: 0.8;
}

.slot-buttons > div {
    transition: 300ms;
    color: white;
    border: 1px solid transparent;
    background-color: #ff385c;
    border-radius: 3px;
    font-size: 16px;
    font-family: Circular;
    font-weight: 400;
    padding: 10px 16px;
    flex: 1;
    text-align: center;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
    display: flex;
    height: 60px;
}

.slot-buttons:not(.disabled) > div:hover {
    background: white;
    color: rgb(41, 41, 41);
    border-color: rgb(141, 141, 141);
}

.slot-buttons.disabled > div {
    background: #f4f4f5;
    color: #d4d4d4;
    cursor: not-allowed;
}

.slot-buttons > div:last-child {
    margin-right: 0;
}

.slot-buttons-heading {
    font-size: 1.25rem;
    font-weight: bold;
    margin: 1em 0;
}

.slot-buttons-heading:first-of-type {
    margin-top: 0;
}

.slot-price {
    color: #d4d4d4;
}

.total-price-label {
    justify-content: center;
    flex-direction: row;
    align-items: center;
    display: flex;
    font-family: Circular;
    font-size: 16px;
    font-weight: 400;
}

.booking-widget.horizontal .selected-date {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: Circular;
    font-size: 16px;
    font-weight: 400;
}

.booking-widget.horizontal {
    box-shadow: none;
}

.slots-error {
    text-align: center;
}

.listado-promos .boton-seleccionar-promo {
    transition: 300ms;
    color: rgb(41, 41, 41);
    border: 1px solid rgb(141, 141, 141);
    background-color: white;
    border-radius: 3px;
    font-size: 16px;
    font-family: Circular;
    font-weight: 400;
    padding: 0 1em;
    flex: 1;
    text-align: center;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 60px;
}

.listado-promos .boton-seleccionar-promo:hover {
    color: white;
    background-color: rgb(41, 41, 41);
}

.booking-widget.booking-widget.horizontal .fa-info-circle {
    margin-left: 5px;
}

.booking-widget.booking-widget.horizontal .icono-info {
    display: inline-block;
}

.preorder-path-selector {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 100%;
}

.preorder-path-selector .btn {
    min-width: 300px;
    margin-top: 25px;
}

.preorder-path-option {
    display: flex;
    text-align: left;
    padding-bottom: 30px;
    margin-top: 30px;
    border-bottom: 1px solid lightgrey;
    margin-left: 10%;
    margin-right: 10%;
}

.preorder-path-option .icon-svg-container {
    align-items: flex-start;
}

.preorder-path-option .option-details {
    margin-left: 25px;
    font-size: 15px;
    flex: 1;
    min-width: 400px;
}

.preorder-path-option .option-details > div {
    color: #333;
}

.preorder-path-option:last-of-type {
    border-bottom: none;
}

.preorder-path-option h3 {
    font-weight: 600;
}

@media(min-width: 992px) {
    .preorder-path-option .icon-svg-container img {
        width: 65px !important;
        height: 65px !important;
    }
}

@media (max-width: 991px) {
    .booking-widget.booking-widget.horizontal .fa-info-circle {
        display: inline-block;
        margin-left: 10px;
    }

    .booking-widget.booking-widget.horizontal .fa-info-circle + div {
        right: 4px;
        bottom: -15px;
        margin-top: unset;
    }

    .booking-widget.booking-widget.horizontal .total-price-label:hover .fa-info-circle + div {
        bottom: -20px;
        opacity: 1;
    }

    .booking-widget.horizontal .selectors {
        flex-direction: row;
        justify-content: flex-start;
    }

    .booking-widget.horizontal .selectors > div.total-price-label {
        position: relative;
        flex: 0;
        padding: 9px;
        min-width: calc(15% + 50px);
        justify-content: center;
        display: flex;
        margin-left: 2%;
        border-color: #f0f0f0;
        font-size: 1.04em;
        color: #333;
        white-space: nowrap;
    }

    .booking-widget .selectors > div {
        height: 32px;
    }

    .slot-buttons-heading {
        font-size: 16px;
    }

    .slot-buttons > div {
        font-size: 14px;
        height: 52px;
    }

    .preorder-path-option {
        display: block;
    }

    .preorder-path-option .icon-svg-container {
        width: 100px !important;
        height: 100px !important;
        margin-bottom: 1em;
        justify-content: flex-start;
    }

    .preorder-path-option .option-details {
        margin-left: 0;
        min-width: unset;
    }

    .preorder-path-option h3.mobile-only {
        float: right;
        height: 100px;
        display: flex !important;
        align-items: center;
        width: calc(100% - 125px);
        font-size: 18px;
        line-height: 1.4;
        padding-top: 7%;
    }

    .price-label-subtitle {
        display: none;
    }

    div.icono-info > .icon-tooltip {
        right: 10px;
        max-width: 400px;
        white-space: normal;
        text-align: left;
        width: 300px;
        z-index: 2;
        height: 68px;
        white-space: normal;
        text-align: right;
    }

    div.icono-info:hover > .icon-tooltip,
    div.icono-info.hover > .icon-tooltip {
        opacity: 1;
        top: 40px;
    }

    .booking-widget.margin-left {
        bottom: 0;
        top: unset;
        position: fixed;
        border-radius: 0;
        border-left-width: 0;
        border-right-width: 0;
        box-shadow: 0 10px rgb(0 0 0 / 10%);
    }

    .booking-widget.margin-left .selectors > div {
        width: auto;
        border: none;
        border-bottom: 1px solid lightgrey;
        padding-bottom: 25px;
        border-radius: 0;
        margin-left: -15px;
        margin-right: -15px;
        padding-left: 10%;
        padding-right: 10%;
    }
}
