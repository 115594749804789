.registro h1 {
	color: var(--gris-texto);
	font-weight: 700;
	font-family: Circular;
	font-size: 20px;
	line-height: 32px;
	letter-spacing: 1px;
	text-transform: uppercase;
	margin-top: 75px;
	margin-bottom: 1em;
}

.registro .form-container {
	max-width: 600px;
	margin-left: auto;
	margin-right: auto;
}

.registro form .columns {
	display: grid;
	grid-template-columns: 2fr 2fr;
	gap: 15px;
}

.registro form .columns.three {
	grid-template-columns: 3fr 3fr 3fr;
}

.registro .btn.btn-brown {
	margin: 25px 0 10px;
	width: 100%;
}

.registro .btn.btn-brown + div {
	margin-bottom: 50px;
}

.registro .form-input input {
	border-radius: 0;
	background: white;
}

.registro .form-input label {
	top: 23px;
}

.registro .form-input input:focus + label,
.registro .form-input input.non-valid:focus + label,
.registro .form-input input:focus + label,
.registro .form-input input.non-valid:focus + label,
.registro .form-input input.non-empty + label,
.registro .form-input textarea:focus + label,
.registro .form-input textarea.non-valid:focus + label,
.registro .form-input textarea.non-empty + label {
	top: 14px;
}

.registro a {
	text-decoration: underline;
}

.registro .error-message {
	text-align: center;
	color: crimson;
	grid-column: span 2;
}

.registro-gracias .container {
	margin-top: 10vh;
	margin-bottom: 25vh;
}

.registro .phone-cell {
	display: flex;
	align-items: stretch;
}

.registro .phone-cell select {
	margin: 5px 5px 0 0;
	border: 1px solid lightgrey;
	background: white;
	cursor: pointer;
}

@media(max-width: 991px) {
	.registro .btn.btn-brown + div {
		padding-top: 1em;
		font-size: 14px;
	}

	.registro .btn.btn-brown {
		margin-bottom: 0;
	}

	.registro .form-input {
		grid-column: span 2 / auto;
	}

	.registro .form .columns {
		gap: 5px;
		margin-bottom: 5px;
	}

	.registro .phone-cell {
		grid-column-start: 1;
		grid-column-end: 3;
		margin-top: 10px;
	}

	.registro .phone-cell .form-input {
		flex: 1;
	}
}
