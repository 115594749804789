.col-md-6 {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.allcaps {
    font-family: Circular;
    color: var(--gris-texto);
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 2px;
    font-weight: 400;
    line-height: 1.5;
}

.text-block {
    padding-left: 10%;
    padding-right: 8%;
}

.text-block h1,
.text-block h2 {
    font-family: Circular;
    color: var(--gris-texto);
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 60px;
    line-height: 1.1;
    letter-spacing: 1px;
}

.text-block p {
    max-width: 90%;
    color: #5c5c5c;
    margin: 1.5rem 0;
    font-family: Circular;
    padding-bottom: 1rem;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 1px;
}

.text-band {
    padding: 80px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.text-band h2 {
    font-family: Circular;
    font-size: 44px;
    line-height: 48px;
    letter-spacing: 1px;
    margin-bottom: 0.5rem;
    font-weight: 500;
    margin-top: 0;
}

.text-band p {
    max-width: 700px;
    color: #5c5c5c;
    margin: 1.5rem 0;
    font-family: Circular;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    width: 75%;
    padding: 0 3rem;
}

.logo-box {
    height: 85px;
    width: 85px;
    border-radius: 0.5rem;
    display: flex;
    margin: 0.5rem 0.5rem;
    align-items: center;
    padding-left: 1%;
    padding-right: 1%;
}

.container.full-width {
    padding: 3rem 0;
}

.container.full-width:first-of-type {
    padding-top: 0;
}

.logos-clientes {
    max-width: 900px;
    margin-top: 2rem;
}
