.time-input-container {
    display: inline-flex;
    align-items: center;
}

.time-input {
    border: 1px solid lightgrey;
    border-radius: 3px;
}

.time-input > input {
    display: inline-block;
    width: 2em;
    text-align: right;
    padding: 5px;
    border: none;
}

.time-input > input:focus {
    outline: none;
}

.time-input > input:last-child {
    text-align: left;
}