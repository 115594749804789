.material-symbols-rounded.clickable {
	cursor: pointer;
}

.material-symbols-rounded.large {
	font-size: 57px;
}

.material-symbols-rounded {
	user-select: none;
}